import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Controller as DocController,
  Action as DocAction,
  Constants,
} from './documentary/doc.configuration';
import { OrganizationUnitModel } from './documentary/models/OrganizationUnitModel';
import { AccountModel } from './documentary/models/AccountModel';
import { MetadataTipologyModel } from './documentary/models/MetadataTipologyModel';
import { MetadataTipologyValueModel } from './documentary/models/MetadataTipologyValueModel';
import { TemplateModel } from './documentary/models/TemplateModel';
import { PermissionModel } from './documentary/models/PermissionModel';
import { TipologyModel } from './documentary/models/TipologyModel';
import { FileModel } from './documentary/models/FileModel';
import { ObjectModel } from './documentary/models/ObjectModel';
import { RoleModel } from './documentary/models/RoleModel';
import { MimeTypeModel } from './documentary/models/MimeTypeModel';
import { FieldsModel } from './documentary/models/FieldsModel';
import { SettingsModel } from './documentary/models/SettingsModel';
import { DossierModel } from './documentary/models/DossierModel';
import { AccountDossierModel } from './documentary/models/AccountDossierModel';
import { ObjectDossierModel } from './documentary/models/ObjectDossierModel';
import { DocumentModel } from './documentary/models/DocumentModel';
import { AccountRoleModel } from './documentary/models/AccountRoleModel';
import { TipologyUoModel } from './documentary/models/TipologyUoModel';
import { UserGroupModel } from './models/UserGroupModel';
import { FavouriteModel } from './documentary/models/FavouriteModel';
import { ActivityModel } from './documentary/models/ActivityModel';
import { UserModel } from './models/UserModel';
import { WorkflowUserModel } from './workflow/models/WorkflowUserModel';
import { WorkflowStepModel } from './workflow/models/WorkflowStepModel';
import { WorkflowModel } from './workflow/models/WorkflowModel';
import { WorkflowDataModel } from './workflow/models/WorkflowDataModel';
import { CurrentUserModel } from './models/CurrentUserModel';
import { GroupModel } from './models/GroupModel';
import { LinkTokenModel } from './models/LinkToken';
import { NotificationModel } from './models/NotificationModel';
import { UserAppsModel } from './models/UserApps';
import { StatisticsModel } from './documentary/models/StatisticsModel';
import { SignatureAccountModel } from './models/SignatureAccount';
import { SignatureServiceModel } from './models/SignatureService';
import { AttachmentModel } from './documentary/models/AttachmentModel';
import { EncryptionModel } from './models/EncryptionModel';
import { AppConfigService } from './app.config.service';
import { AuthService } from './auth.service';
import { DocClassModel } from './documentary/models/DocClassModel';
import { DocFlyDocClassModel } from './documentary/models/DocFlyDocClassModel';
import { SystemInfoModel } from './documentary/models/SystemInfoModel';
import { ViewRepositoryModel } from './documentary/models/SystemInfoModel copy';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class DataService {
  constructor(
    private http: HttpClient,
    private configAppService: AppConfigService,
    private authService: AuthService
  ) {}

  public async AsyncPost<TModel>(
    controller: string,
    action: string,
    requestModel: TModel
  ) {
    await this.authService.RefreshToken();
    const response = await lastValueFrom(
      this.http.post<TModel>(
        `${this.configAppService.apiUrl}` +
          '/' +
          Constants.AppId +
          (controller ? '/' + controller : '') +
          '/' +
          action,
        requestModel,
        { observe: 'response' }
      )
    );
    return response;
  }
  public async AsyncGet<TModel>(
    controller: string,
    action: string,
    requestModel: TModel = null
  ) {
    await this.authService.RefreshToken();
    const response = await lastValueFrom(
      this.http.get<any>(
        `${this.configAppService.apiUrl}` +
          '/' +
          Constants.AppId +
          (controller ? '/' + controller : '') +
          '/' +
          action,
        { observe: 'response' }
      )
    );
    return response;
  }

  public async AsyncITCorePost<TModel>(
    controller: string,
    action: string,
    requestModel: TModel
  ) {
    this.authService.RefreshToken();
    const response = await lastValueFrom(
      this.http.post<TModel>(
        `${this.configAppService.apiUrl}` +
          (controller ? '/' + controller : '') +
          '/' +
          action,
        requestModel,
        { observe: 'response' }
      )
    );
    return response;
  }

  public async AsyncITCoreGet<TModel>(controller: string, action: string) {
    this.authService.RefreshToken();
    const response = await lastValueFrom(
      this.http.get<TModel>(
        `${this.configAppService.apiUrl}` +
          (controller ? '/' + controller : '') +
          '/' +
          action,
        { observe: 'response' }
      )
    );
    return response;
  }

  // #region doc
  //#region "Uo"
  public async GetOrganizationalUnit(requestModel: OrganizationUnitModel) {
    const response = await this.AsyncPost(
      DocController.Uo,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateOrganizationalUnit(
    requestModel: OrganizationUnitModel
  ) {
    const response = await this.AsyncPost(
      DocController.Uo,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ReadOrganizationalUnit(requestModel: OrganizationUnitModel) {
    const response = await this.AsyncPost(
      DocController.Uo,
      DocAction.Read,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetHierarchyOrganizationalUnit(
    requestModel: OrganizationUnitModel
  ) {
    const response = await this.AsyncPost(
      DocController.Uo,
      'hierarchy',
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteOrganizationUnit(
    requestModel: OrganizationUnitModel
  ): Promise<OrganizationUnitModel> {
    const response = await this.AsyncPost(
      DocController.Uo,
      DocAction.Delete,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  // #endregion

  //#region "Account"
  public async LoadAccount(requestModel: AccountModel) {
    const response = await this.AsyncPost(
      DocController.Account,
      DocAction.Load,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetAccount(requestModel: AccountModel): Promise<AccountModel> {
    const response = await this.AsyncPost(
      DocController.Account,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateAccount(
    requestModel: AccountModel
  ): Promise<AccountModel> {
    const response = await this.AsyncPost(
      DocController.Account,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteAccount(
    requestModel: AccountModel
  ): Promise<AccountModel> {
    const response = await this.AsyncPost(
      DocController.Account,
      DocAction.Delete,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  // #endregion

  // #region MetadataTipology
  public async DeleteMetaDataTipology(
    requestModel: MetadataTipologyModel
  ): Promise<MetadataTipologyModel> {
    const response = await this.AsyncPost(
      DocController.MetadataTipology,
      DocAction.Delete,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async LoadMetaDataTipology(
    requestModel: MetadataTipologyModel
  ): Promise<MetadataTipologyModel> {
    const response = await this.AsyncPost(
      DocController.MetadataTipology,
      DocAction.Load,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async GetMetaDataTipology(
    requestModel: MetadataTipologyModel
  ): Promise<MetadataTipologyModel> {
    const response = await this.AsyncPost(
      DocController.MetadataTipology,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async ReadMetaDataTipology(
    requestModel: MetadataTipologyModel
  ): Promise<MetadataTipologyModel> {
    const response = await this.AsyncPost(
      DocController.MetadataTipology,
      DocAction.Read,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async CreateOrUpdateMetaDataTipology(
    requestModel: MetadataTipologyModel
  ): Promise<MetadataTipologyModel> {
    const response = await this.AsyncPost(
      DocController.MetadataTipology,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  // #endregion

  // #region MetadataTipologyValue
  public async DeleteMetadataTipologyValue(
    requestModel: MetadataTipologyValueModel
  ): Promise<MetadataTipologyValueModel> {
    const response = await this.AsyncPost(
      DocController.MetadataTipologyValue,
      DocAction.Delete,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async LoadMetadataTipologyValue(
    requestModel: MetadataTipologyValueModel
  ): Promise<MetadataTipologyValueModel> {
    const response = await this.AsyncPost(
      DocController.MetadataTipologyValue,
      DocAction.Load,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async GetMetadataTipologyValue(
    requestModel: MetadataTipologyValueModel
  ): Promise<MetadataTipologyValueModel> {
    const response = await this.AsyncPost(
      DocController.MetadataTipologyValue,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ReadMetadataTipologyValue(
    requestModel: MetadataTipologyValueModel
  ): Promise<MetadataTipologyValueModel> {
    const response = await this.AsyncPost(
      DocController.MetadataTipologyValue,
      DocAction.Read,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async CreateOrUpdateMetadataTipologyValue(
    requestModel: MetadataTipologyValueModel
  ): Promise<MetadataTipologyValueModel> {
    const response = await this.AsyncPost(
      DocController.MetadataTipologyValue,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  // #endregion

  // #region Template
  public async DeleteTemplate(
    requestModel: TemplateModel
  ): Promise<TemplateModel> {
    const response = await this.AsyncPost(
      DocController.Template,
      DocAction.Delete,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async LoadTemplate(
    requestModel: TemplateModel
  ): Promise<TemplateModel> {
    const response = await this.AsyncPost(
      DocController.Template,
      DocAction.Load,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetTemplate(
    requestModel: TemplateModel
  ): Promise<TemplateModel> {
    const response = await this.AsyncPost(
      DocController.Template,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ReadTemplate(
    requestModel: TemplateModel
  ): Promise<TemplateModel> {
    const response = await this.AsyncPost(
      DocController.Template,
      DocAction.Read,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateTemplate(
    requestModel: TemplateModel
  ): Promise<TemplateModel> {
    const response = await this.AsyncPost(
      DocController.Template,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  // #endregion

  // #region Permission
  public async CreateOrUpdatePermission(
    requestModel: PermissionModel
  ): Promise<PermissionModel> {
    const response = await this.AsyncPost(
      DocController.Permission,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetPermission(
    requestModel: PermissionModel
  ): Promise<PermissionModel> {
    const response = await this.AsyncPost(
      DocController.Permission,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeletePermission(
    requestModel: PermissionModel
  ): Promise<PermissionModel> {
    const response = await this.AsyncPost(
      DocController.Permission,
      DocAction.Delete,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  // #endregion

  // #region Tipology
  public async GetTipology(
    requestModel: TipologyModel
  ): Promise<TipologyModel> {
    const response = await this.AsyncPost(
      DocController.Tipology,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async LoadTipology(
    requestModel: TipologyModel
  ): Promise<TipologyModel> {
    const response = await this.AsyncPost(
      DocController.Tipology,
      DocAction.Load,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateTipology(
    requestModel: TipologyModel
  ): Promise<TipologyModel> {
    const response = await this.AsyncPost(
      DocController.Tipology,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ReadTipology(
    requestModel: TipologyModel
  ): Promise<TipologyModel> {
    const response = await this.AsyncPost(
      DocController.Tipology,
      DocAction.Read,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  // #endregion

  public async AsyncPostUpload<TModel, TModelOptions>(
    body: TModel,
    options: TModelOptions | any
  ): Promise<any> {
    await this.authService.RefreshToken();
    const url =
      `${this.configAppService.apiUrl}` +
      '/' +
      Constants.AppId +
      '/' +
      DocController.FileManager +
      '/' +
      DocAction.Upload;
    const response = await lastValueFrom(
      this.http.post<TModel>(url, body, options)
    );
    return response;
  }

  public async Download(requestModel: FileModel) {
    await this.authService.RefreshToken();
    const response = await lastValueFrom(
      this.http.post(
        `${this.configAppService.apiUrl}` +
          '/' +
          Constants.AppId +
          (DocController.FileManager
            ? '/' + DocController.FileManager
            : '') +
          '/' +
          DocAction.Download,
        requestModel,
        { observe: 'response', responseType: 'arraybuffer' }
      )
    );
    if (response) {
      requestModel.Performed = true;
      requestModel.Dto.File = response?.body;
    } else {
      requestModel.Performed = false;
    }
    return requestModel;
  }

  public async ScanToPdf(requestModel: FileModel) {
    const response = await this.AsyncPost(
      DocController.FileManager,
      DocAction.ScanToPdf,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    } else {
      requestModel.Performed = false;
    }
    return requestModel;
  }
  // #endregion

  // #region Object
  public async CountObject(requestModel: ObjectModel): Promise<ObjectModel> {
    const response = await this.AsyncPost(
      DocController.Object,
      DocAction.Count,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetObject(requestModel: ObjectModel): Promise<ObjectModel> {
    const response = await this.AsyncPost(
      DocController.Object,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async LoadObject(requestModel: ObjectModel): Promise<ObjectModel> {
    const response = await this.AsyncPost(
      DocController.Object,
      DocAction.Load,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ReadObject(requestModel: ObjectModel): Promise<ObjectModel> {
    const response = await this.AsyncPost(
      DocController.Object,
      DocAction.Read,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteObject(requestModel: ObjectModel): Promise<ObjectModel> {
    const response = await this.AsyncPost(
      DocController.Object,
      DocAction.Delete,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateObject(
    requestModel: ObjectModel
  ): Promise<ObjectModel> {
    const response = await this.AsyncPost(
      DocController.Object,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ProtocolObject(requestModel: ObjectModel): Promise<ObjectModel> {
    const response = await this.AsyncPost(
      DocController.Object,
      DocAction.Protocol,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  //#endregion

  //#region Activity
  public async LoadActivity(
    requestModel: ActivityModel
  ): Promise<ActivityModel> {
    const response = await this.AsyncPost(
      DocController.Activity,
      DocAction.Load,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
    }
    return requestModel;
  }
  public async GetActivity(
    requestModel: ActivityModel
  ): Promise<ActivityModel> {
    const response = await this.AsyncPost(
      DocController.Activity,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      // requestModel.Code = response.Code;
    }
    return requestModel;
  }

  //#endregion

  public async GetRole(requestModel: RoleModel) {
    const response = await this.AsyncPost(
      DocController.Role,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async GetMimeType(
    requestModel: MimeTypeModel
  ): Promise<MimeTypeModel> {
    const response = await this.AsyncPost(
      DocController.MimeType,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateMimeType(
    requestModel: MimeTypeModel
  ): Promise<MimeTypeModel> {
    const response = await this.AsyncPost(
      DocController.Account,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetFields(requestModel: FieldsModel): Promise<FieldsModel> {
    const response = await this.AsyncPost(
      DocController.Fields,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateFields(
    requestModel: FieldsModel
  ): Promise<FieldsModel> {
    const response = await this.AsyncPost(
      DocController.Fields,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ReadAccount(requestModel: AccountModel): Promise<AccountModel> {
    const response = await this.AsyncITCorePost(
      DocController.Account,
      DocAction.Read,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetAppSettings(
    requestModel: SettingsModel
  ): Promise<SettingsModel> {
    const response = await this.AsyncITCoreGet<SettingsModel>(
      DocController.Settings,
      'app-settings'
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetConfigKey(
    requestModel: SettingsModel
  ): Promise<SettingsModel> {
    const response = await this.AsyncITCorePost(
      DocController.Settings,
      DocAction.GetConfigKey,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async SettingsSave(
    requestModel: SettingsModel
  ): Promise<SettingsModel> {
    const response = await this.AsyncITCorePost(
      DocController.Settings,
      DocAction.Set,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetGroupMail(requestModel: any): Promise<any> {
    const response = await this.AsyncPost(
      DocController.Account + '/' + DocController.UsersGroupsMails,
      DocAction.Get,
      requestModel
    );
    return response;
  }

  public async CreateOrUpdateDossier(
    requestModel: DossierModel
  ): Promise<DossierModel> {
    const response = await this.AsyncPost(
      DocController.Dossier,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetDossier(requestModel: DossierModel): Promise<DossierModel> {
    const response = await this.AsyncPost(
      DocController.Dossier,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async LoadDossier(requestModel: DossierModel): Promise<DossierModel> {
    const response = await this.AsyncPost(
      DocController.Dossier,
      DocAction.Load,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ReadDossier(requestModel: DossierModel): Promise<DossierModel> {
    const response = await this.AsyncPost(
      DocController.Dossier,
      DocAction.Read,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteDossier(
    requestModel: DossierModel
  ): Promise<DossierModel> {
    const response = await this.AsyncPost(
      DocController.Dossier,
      DocAction.Delete,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateAccountDossier(
    requestModel: AccountDossierModel
  ): Promise<AccountDossierModel> {
    const response = await this.AsyncPost(
      DocController.AccountDossier,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetAccountDossier(
    requestModel: AccountDossierModel
  ): Promise<AccountDossierModel> {
    const response = await this.AsyncPost(
      DocController.AccountDossier,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ReadAccountDossier(
    requestModel: AccountDossierModel
  ): Promise<AccountDossierModel> {
    const response = await this.AsyncPost(
      DocController.AccountDossier,
      DocAction.Read,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteAccountDossier(
    requestModel: AccountDossierModel
  ): Promise<AccountDossierModel> {
    const response = await this.AsyncPost(
      DocController.AccountDossier,
      DocAction.Delete,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateObjectDossier(
    requestModel: ObjectDossierModel
  ): Promise<ObjectDossierModel> {
    const response = await this.AsyncPost(
      DocController.ObjectDossier,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetObjectDossier(
    requestModel: ObjectDossierModel
  ): Promise<ObjectDossierModel> {
    const response = await this.AsyncPost(
      DocController.ObjectDossier,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async LoadObjectDossier(
    requestModel: ObjectDossierModel
  ): Promise<ObjectDossierModel> {
    const response = await this.AsyncPost(
      DocController.ObjectDossier,
      DocAction.Load,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async ReadObjectDossier(
    requestModel: ObjectDossierModel
  ): Promise<ObjectDossierModel> {
    const response = await this.AsyncPost(
      DocController.ObjectDossier,
      DocAction.Read,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteObjectDossier(
    requestModel: ObjectDossierModel
  ): Promise<ObjectDossierModel> {
    const response = await this.AsyncPost(
      DocController.ObjectDossier,
      DocAction.Delete,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateFromTemplate(requestModel: DocumentModel) {
    const response = await this.AsyncPost(
      DocController.Template,
      DocAction.CreateFromTemplate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
      requestModel.Dto = response?.body?.Dto;
    }
    return requestModel;
  }

  //#region OnlyOffice
  public async CreateOnlyOfficeFile(
    requestModel: FileModel
  ): Promise<FileModel> {
    const response = await this.AsyncPost(
      DocController.OnlyOffice,
      DocAction.Create,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
      requestModel.Dto = response?.body?.Dto;
    } else {
      requestModel.Performed = false;
    }
    return requestModel;
  }

  public async OpenOnlyOfficeFile(requestModel: FileModel): Promise<any> {
    const response = await this.AsyncPost(
      DocController.OnlyOffice,
      DocAction.Open,
      requestModel
    );
    return response?.body;
  }

  public async ConvertOnlyOfficeFile(requestModel: FileModel): Promise<any> {
    const response = await this.AsyncPost(
      DocController.OnlyOffice,
      DocAction.Convert,
      requestModel
    );
    return response?.body;
  }

  //#endregion

  public async GetAccountRole(
    requestModel: AccountRoleModel
  ): Promise<AccountRoleModel> {
    const response = await this.AsyncPost(
      DocController.AccountRole,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteAccountRole(
    requestModel: AccountRoleModel
  ): Promise<AccountRoleModel> {
    const response = await this.AsyncPost(
      DocController.AccountRole,
      DocAction.Delete,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteTipology(
    requestModel: TipologyModel
  ): Promise<TipologyModel> {
    const response = await this.AsyncPost(
      DocController.Tipology,
      DocAction.Delete,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteTipologyUo(
    requestModel: TipologyUoModel
  ): Promise<TipologyUoModel> {
    const response = await this.AsyncPost(
      DocController.TipologyUo,
      DocAction.Delete,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateTipologyUo(
    requestModel: TipologyUoModel
  ): Promise<TipologyUoModel> {
    const response = await this.AsyncPost(
      DocController.TipologyUo,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetFavourites(requestModel: FavouriteModel) {
    const response = await this.AsyncPost(
      DocController.Favourites,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateFavourite(
    requestModel: FavouriteModel
  ): Promise<FavouriteModel> {
    const response = await this.AsyncPost(
      DocController.Favourites,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteFavourite(
    requestModel: FavouriteModel
  ): Promise<FavouriteModel> {
    const response = await this.AsyncPost(
      DocController.Favourites,
      DocAction.Delete,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  //#endregion

  //#region Core
  public async IsAdmin(): Promise<boolean> {
    const response = await this.AsyncITCoreGet(
      DocController.User,
      DocAction.IsAdmin
    );
    if (response?.body) {
      return response.body['IsAdmin'];
    }
    return false;
  }

  public async GetUsersGroup(
    requestModel: UserGroupModel
  ): Promise<UserGroupModel> {
    const response = await this.AsyncITCorePost(
      DocController.UserGroup,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
    }
    return requestModel;
  }

  public async CreateOrUpdateUsersGroup(
    requestModel: UserGroupModel
  ): Promise<UserGroupModel> {
    const response = await this.AsyncITCorePost(
      DocController.UserGroup,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
    }
    return requestModel;
  }

  public async DeleteUsersGroup(
    requestModel: UserGroupModel
  ): Promise<UserGroupModel> {
    const response = await this.AsyncITCorePost(
      DocController.UserGroup,
      DocAction.Delete,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async SearchUser(requestModel: UserModel): Promise<UserModel> {
    const response = await this.AsyncITCorePost(
      DocController.User,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
    }
    return requestModel;
  }
  public async SearchUserDoc(requestModel: UserModel): Promise<UserModel> {
    const response = await this.AsyncPost(
      DocController.User,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
    }
    return requestModel;
  }
  public async ReadUser(requestModel: UserModel): Promise<UserModel> {
    const response = await this.AsyncPost(
      DocController.User,
      DocAction.Read,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
    }
    return requestModel;
  }
  //#endregion

  // #region workflow
  public async LoadWorkflow(
    requestModel: WorkflowModel
  ): Promise<WorkflowModel> {
    const response = await this.AsyncPost(
      DocController.Workflow,
      DocAction.Load,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
      requestModel.Count = response?.body?.Count;
    }
    return requestModel;
  }
  public async DeleteWorkflow(
    requestModel: WorkflowModel
  ): Promise<WorkflowModel> {
    const response = await this.AsyncPost(
      DocController.Workflow,
      DocAction.Delete,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async GetWorkflow(
    requestModel: WorkflowModel
  ): Promise<WorkflowModel> {
    const response = await this.AsyncPost(
      DocController.Workflow,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async ReadWorkflow(
    requestModel: WorkflowModel
  ): Promise<WorkflowModel> {
    const response = await this.AsyncPost(
      DocController.Workflow,
      DocAction.Read,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async CreateOrUpdateWorkflow(
    requestModel: WorkflowModel
  ): Promise<WorkflowModel> {
    const response = await this.AsyncPost(
      DocController.Workflow,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async LoadWorkflowStep(
    requestModel: WorkflowStepModel
  ): Promise<WorkflowStepModel> {
    const response = await this.AsyncPost(
      DocController.WorkflowStep,
      DocAction.Load,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async DeleteWorkflowStep(
    requestModel: WorkflowStepModel
  ): Promise<WorkflowStepModel> {
    const response = await this.AsyncPost(
      DocController.WorkflowStep,
      DocAction.Delete,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async GetWorkflowStep(
    requestModel: WorkflowStepModel
  ): Promise<WorkflowStepModel> {
    const response = await this.AsyncPost(
      DocController.WorkflowStep,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async ReadWorkflowStep(
    requestModel: WorkflowStepModel
  ): Promise<WorkflowStepModel> {
    const response = await this.AsyncPost(
      DocController.WorkflowStep,
      DocAction.Read,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async CreateOrUpdateWorkflowStep(
    requestModel: WorkflowStepModel
  ): Promise<WorkflowStepModel> {
    const response = await this.AsyncPost(
      DocController.WorkflowStep,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async LoadWorkflowUser(
    requestModel: WorkflowUserModel
  ): Promise<WorkflowUserModel> {
    const response = await this.AsyncPost(
      DocController.WorkflowUser,
      DocAction.Load,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async DeleteWorkflowUser(
    requestModel: WorkflowUserModel
  ): Promise<WorkflowUserModel> {
    const response = await this.AsyncPost(
      DocController.WorkflowUser,
      DocAction.Delete,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async GetWorkflowUser(
    requestModel: WorkflowUserModel
  ): Promise<WorkflowUserModel> {
    const response = await this.AsyncPost(
      DocController.WorkflowUser,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async ReadWorkflowUser(
    requestModel: WorkflowUserModel
  ): Promise<WorkflowUserModel> {
    const response = await this.AsyncPost(
      DocController.WorkflowUser,
      DocAction.Read,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async CreateOrUpdateWorkflowUser(
    requestModel: WorkflowUserModel
  ): Promise<WorkflowUserModel> {
    const response = await this.AsyncPost(
      DocController.WorkflowUser,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async RunWorkflowData(
    requestModel: WorkflowDataModel
  ): Promise<WorkflowDataModel> {
    const response = await this.AsyncPost(
      DocController.WorkflowData,
      DocAction.Run,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteWorkflowData(requestModel: WorkflowDataModel) {
    const response = await this.AsyncPost(
      DocController.WorkflowData,
      DocAction.Delete,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ReadWorkflowData(
    requestModel: WorkflowDataModel
  ): Promise<WorkflowDataModel> {
    const response = await this.AsyncPost(
      DocController.WorkflowData,
      DocAction.Read,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  // #endregion

  // #region current user
  public async GetSettingsUser(
    requestModel: CurrentUserModel
  ): Promise<CurrentUserModel> {
    const response = await this.AsyncPost(
      DocController.User,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
    }
    return requestModel;
  }
  public async LoadSettingsUser(
    requestModel: CurrentUserModel
  ): Promise<CurrentUserModel> {
    const response = await this.AsyncPost(
      DocController.User,
      DocAction.Load,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async ReadSettingsUser(
    requestModel: CurrentUserModel
  ): Promise<CurrentUserModel> {
    const response = await this.AsyncPost(
      DocController.User,
      DocAction.Read,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
    }
    return requestModel;
  }

  public async CreateOrUpdateUser(
    requestModel: CurrentUserModel
  ): Promise<CurrentUserModel> {
    const response = await this.AsyncPost(
      DocController.User,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async SearchUsersLdap(
    requestModel: CurrentUserModel
  ): Promise<CurrentUserModel> {
    const response = await this.AsyncITCorePost(
      DocController.User,
      DocAction.LdapSearch,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async LdapEnabled(): Promise<boolean> {
    const response = await this.AsyncITCoreGet(
      DocController.User,
      'ldapenabled'
    );
    if (response) {
      return response.body?.toString() === 'true';
    }
    return false;
  }
  // #endregion

  // #region group
  public async GetGroup(requestModel: GroupModel): Promise<GroupModel> {
    const response = await this.AsyncITCorePost(
      DocController.Group,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
    }
    return requestModel;
  }
  // #endregion

  // #region invitation
  public async SendInvitation(
    requestModel: LinkTokenModel
  ): Promise<LinkTokenModel> {
    const response = await this.AsyncPost(
      DocController.Invitation,
      DocAction.Send,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetInvitation(
    requestModel: LinkTokenModel
  ): Promise<LinkTokenModel> {
    const response = await this.AsyncPost(
      DocController.Invitation,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
    }
    return requestModel;
  }

  public async GetResetTokenFirst(
    requestModel: LinkTokenModel
  ): Promise<LinkTokenModel> {
    const response = await this.AsyncPost(
      DocController.ResetPassword,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
    }
    return requestModel;
  }

  public async AcceptInvitation(
    requestModel: LinkTokenModel
  ): Promise<LinkTokenModel> {
    const response = await this.AsyncPost(
      DocController.Invitation,
      DocAction.Accept,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ResetPassword(
    requestModel: LinkTokenModel
  ): Promise<LinkTokenModel> {
    const response = await this.AsyncPost(
      DocController.ResetPassword,
      DocAction.Reset,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  // #endregion

  // #region user apps
  public async DeleteUserApps(requestModel: UserAppsModel) {
    const response = await this.AsyncITCorePost(
      DocController.UserApps,
      DocAction.Delete,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateUserApps(requestModel: UserAppsModel) {
    const response = await this.AsyncITCorePost(
      DocController.UserApps,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  // #endregion

  // #region notification
  public async GetNotification(
    requestModel: NotificationModel
  ): Promise<NotificationModel> {
    const response = await this.AsyncPost(
      DocController.Notification,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
    }
    return requestModel;
  }

  public async LoadNotificationHistory(
    requestModel: NotificationModel
  ): Promise<NotificationModel> {
    const response = await this.AsyncPost(
      DocController.Notification,
      'all',
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
    }
    return requestModel;
  }

  public async MarkNotification(
    requestModel: NotificationModel
  ): Promise<NotificationModel> {
    const response = await this.AsyncITCorePost(
      DocController.Notification,
      'mark',
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
    }
    return requestModel;
  }
  public async NewWorkflow(
    requestModel: NotificationModel
  ): Promise<NotificationModel> {
    const response = await this.AsyncPost(
      DocController.Notification,
      'newworkflow',
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async NewUo(
    requestModel: NotificationModel
  ): Promise<NotificationModel> {
    const response = await this.AsyncPost(
      DocController.Notification,
      'newuo',
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async NewDossier(
    requestModel: NotificationModel
  ): Promise<NotificationModel> {
    const response = await this.AsyncPost(
      DocController.Notification,
      'newdossier',
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  // #endregion

  public async ReadStatistics(
    requestModel: StatisticsModel
  ): Promise<StatisticsModel> {
    const response = await this.AsyncPost(
      DocController.Statistics,
      DocAction.Read,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateStatistics(
    requestModel: StatisticsModel
  ): Promise<StatisticsModel> {
    const response = await this.AsyncPost(
      DocController.Statistics,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  //#region signature
  public async GetSignatureService(
    requestModel: SignatureServiceModel
  ): Promise<SignatureServiceModel> {
    const response = await this.AsyncITCorePost(
      DocController.SignatureService,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async GetSignatureAccount(
    requestModel: SignatureAccountModel
  ): Promise<SignatureAccountModel> {
    const response = await this.AsyncITCorePost(
      DocController.SignatureAccount,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CreateOrUpdateSignatureAccount(
    requestModel: SignatureAccountModel
  ): Promise<SignatureAccountModel> {
    const response = await this.AsyncITCorePost(
      DocController.SignatureAccount,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteSignatureAccount(
    requestModel: SignatureAccountModel
  ): Promise<SignatureAccountModel> {
    const response = await this.AsyncITCorePost(
      DocController.SignatureAccount,
      DocAction.Delete,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async Sign(
    requestModel: SignatureAccountModel
  ): Promise<SignatureAccountModel> {
    const response = await this.AsyncPost(
      DocController.Signature,
      DocAction.Sign,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async SignatureVerify(requestModel: FileModel): Promise<any> {
    const response = await this.AsyncITCorePost(
      DocController.Signature,
      'verify',
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async ExtractP7M(requestModel: FileModel): Promise<any> {
    const response = await this.AsyncITCorePost(
      DocController.Signature,
      'extractp7m',
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetPreview(requestModel: FileModel): Promise<FileModel> {
    const response = await this.AsyncITCorePost(
      DocController.Signature,
      DocAction.Get + '/preview',
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
      requestModel.Count = response?.body?.Count;
    }
    return requestModel;
  }
  //#endregion

  //#region "Attachment"
  public async LoadAttachment(requestModel: AttachmentModel) {
    const response = await this.AsyncPost(
      DocController.Attachment,
      DocAction.Load,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async GetAttachment(
    requestModel: AttachmentModel
  ): Promise<AttachmentModel> {
    const response = await this.AsyncPost(
      DocController.Attachment,
      DocAction.Get,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async ReadAttachment(
    requestModel: AttachmentModel
  ): Promise<AttachmentModel> {
    const response = await this.AsyncPost(
      DocController.Attachment,
      DocAction.Read,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Count = response?.body?.Count;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  public async CreateOrUpdateAttachment(
    requestModel: AttachmentModel
  ): Promise<AttachmentModel> {
    const response = await this.AsyncPost(
      DocController.Attachment,
      DocAction.CreateOrUpdate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async DeleteAttachment(
    requestModel: AttachmentModel
  ): Promise<AttachmentModel> {
    const response = await this.AsyncPost(
      DocController.Attachment,
      DocAction.Delete,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }
  // #endregion

  public async Decrypt(
    requestModel: EncryptionModel
  ): Promise<EncryptionModel> {
    const response = await this.AsyncPost(
      DocController.File,
      DocAction.Decrypt,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async Encrypt(
    requestModel: EncryptionModel
  ): Promise<EncryptionModel> {
    const response = await this.AsyncPost(
      DocController.File,
      DocAction.Encrypt,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  public async CheckEncryptionPassword(
    requestModel: EncryptionModel
  ): Promise<EncryptionModel> {
    const response = await this.AsyncPost(
      DocController.File,
      'checkencryptionpassword',
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  // #region docFly
  public async GetDocumentModel(requestModel: DocClassModel) {
    const response = await this.AsyncPost(
      DocController.Conservation,
      DocAction.ModelGet,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  async GetDocFlyDocClass(requestModel: DocFlyDocClassModel) {
    const response = await this.AsyncPost(
      DocController.DocFly,
      DocAction.ClassGet,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  async GetArchivesInfo(requestModel: SystemInfoModel) {
    const response = await this.AsyncPost(
      DocController.Conservation,
      DocAction.GetSystemInfo,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  async DocFlyLogin(
    username: string,
    password: string,
    saveCredential: boolean,
    autoLogin: boolean = false
  ) {
    const response = await this.AsyncPost(
      DocController.Conservation,
      DocAction.Login,
      {
        Username: username,
        Password: password,
        SaveCredential: saveCredential,
        AutoLogin: autoLogin,
      }
    );
    return response?.body;
  }

  async GetViewRepository(requestModel: ViewRepositoryModel) {
    const response = await this.AsyncPost(
      DocController.Conservation,
      DocAction.GetRepositoryInfo,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  async DownloadFile(requestModel: ViewRepositoryModel) {
    await this.authService.RefreshToken();
    const response = await lastValueFrom(
      this.http.post(
        `${this.configAppService.apiUrl}` +
          '/' +
          Constants.AppId +
          '/' +
          DocController.Conservation +
          '/' +
          DocAction.Download,
        requestModel,
        { observe: 'response', responseType: 'arraybuffer' }
      )
    );
    if (response) {
      requestModel.Performed = true;
      requestModel.Dto.File = response?.body;
    } else {
      requestModel.Performed = false;
    }
    return requestModel;
  }

  async DownloadPDD(requestModel: ViewRepositoryModel) {
    await this.authService.RefreshToken();
    const response = await lastValueFrom(
      this.http.post(
        `${this.configAppService.apiUrl}` +
          '/' +
          Constants.AppId +
          '/' +
          DocController.Conservation +
          '/' +
          DocAction.DownloadPDD,
        requestModel,
        { observe: 'response', responseType: 'arraybuffer' }
      )
    );
    if (response) {
      requestModel.Performed = true;
      requestModel.Dto.File = response?.body;
    } else {
      requestModel.Performed = false;
    }
    return requestModel;
  }

  async PdvSend(requestModel: DocClassModel) {
    const response = await this.AsyncPost(
      DocController.Conservation,
      DocAction.PdvSend,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  async FilesSend(requestModel: DocClassModel) {
    const response = await this.AsyncPost(
      DocController.Conservation,
      DocAction.FilesSend,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  async CreatePdv(requestModel: DocClassModel) {
    const response = await this.AsyncPost(
      DocController.Conservation,
      DocAction.PdvCreate,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
      requestModel.PdvModels = response?.body?.PdvModels;
    }
    return requestModel;
  }
  async DeletePda(requestModel: ViewRepositoryModel) {
    const response = await this.AsyncPost(
      DocController.Conservation,
      DocAction.PdaDelete,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  async GetDisposedLog(requestModel: ViewRepositoryModel) {
    const response = await this.AsyncPost(
      DocController.Conservation,
      DocAction.PdaLog,
      requestModel
    );
    if (response) {
      requestModel.Performed = response?.body?.Performed;
      requestModel.Dtos = response?.body?.Dtos;
      requestModel.Dto = response?.body?.Dto;
      requestModel.Entity = response?.body?.Entity;
      requestModel.Message = response?.body?.Message;
      requestModel.Code = response?.body?.Code;
    }
    return requestModel;
  }

  async CheckAutoLogin(): Promise<any> {
    const response = await this.AsyncGet(
      DocController.Conservation,
      DocAction.CheckAutologin
    );
    if (response?.body) {
      return response?.body?.Dto?.IsAutoLogin;
    }
    return false;
  }
  // #endregion docFly
}
