<div id="itdoc">
  <div id="sidebar">
    <div class="sidebar-logo">
      <img [src]="whiteLabelService.BrandLogo">
    </div>
    <div class="menu default-scrollbar">
      <div>
        <mat-list *ngIf="navigatorService?.DashboardMenu">
          <app-sidebar-menu [MenuItem]="navigatorService?.DashboardMenu" (ItemClick)="SidebarMenuClick($event)">
          </app-sidebar-menu>
        </mat-list>

        <mat-list *ngIf="navigatorService.DocMenu">
          <app-sidebar-menu *ngFor="let menu of navigatorService?.DocMenu" [MenuItem]="menu"
            (ItemClick)="SidebarMenuClick($event)" class="item-menu"></app-sidebar-menu>
        </mat-list>
        <div class="d-flex justify-content-center">
          <hr>
        </div>
        <mat-list *ngIf="navigatorService.UserProfileMenu">
          <app-sidebar-menu *ngFor="let menu of navigatorService?.UserProfileMenu" [MenuItem]="menu"
            (ItemClick)="ProfileMenuItemClick($event)" class="item-menu"></app-sidebar-menu>
        </mat-list>


        <div *ngFor="let item of navigatorService.UserProfileMenu">
          <div *ngIf="item.Name==='user-profile'" class="user-menu-container" [ngClass]="{'selected': item?.Selected}"
            (click)="ProfileMenuItemClick(item)">
            <img *ngIf="authenticationService?.CurrentUser?.Avatar" class="profile-picture"
              src="data:image/png;base64,{{authenticationService?.CurrentUser?.Avatar}}">
            <div>
              <div class="user-name-txt">
                <span>{{authenticationService?.CurrentUser?.Displayname}}</span>
              </div>
              <div class="user-role-txt">{{commonService.GetUserRole(authenticationService?.CurrentUser)}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <mat-list *ngIf="navigatorService?.SettingsMenu">
          <app-sidebar-menu [MenuItem]="navigatorService?.SettingsMenu" (ItemClick)="SidebarMenuClick($event)">
          </app-sidebar-menu>
        </mat-list>
        <div class="left-logo-container row m-0 p-0">
          <div class="logo-col col-12 mt-auto mb-0 px-3 py-0">
            <img *ngIf="whiteLabelService.LogoPoweredLeft" class="bottom-logo"
              [src]="whiteLabelService.LogoPoweredLeft" />
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="container-itdoc">
    <div class="container-header">
      <app-header></app-header>
    </div>
    <div class="itdoc-app-container default-scrollbar">
      <ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" size="medium" color="#0671f2" type="ball-spin-clockwise"
        [fullScreen]="true">
      </ngx-spinner>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
