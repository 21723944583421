import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../auth.service';
import { CommonService } from '../common.service';
import { DataService } from '../data.service';
import { Dictionary } from '../dictionary/dictionary';
import { OrganizationUnitController } from '../documentary/controllers/OrganizationUnitController';
import {
  OrganizationUnitDto,
  OrganizationUnitFilter,
  OrganizationUnitModel,
} from '../documentary/models/OrganizationUnitModel';
import { ProfileInfoComponent } from '../documentary/pages/dialog-menu/profile-info/profile-info.component';
import { BaseOrder } from '../models/BaseModel';
import { CurrentUserModel } from '../models/CurrentUserModel';
import { NavigatorService } from '../navigator.services';
import { ApplicationSidebar as DocApplicationSidebar } from '../documentary/doc.configuration';
import { WhiteLabelService } from '../white-label.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  Tab = 1;
  UOModel: OrganizationUnitModel;
  UOController: OrganizationUnitController;
  UODataSource: MatTableDataSource<OrganizationUnitDto>;

  constructor(
    public authenticationService: AuthService,
    public navigatorService: NavigatorService,
    public commonService: CommonService,
    dataService: DataService,
    public whiteLabelService: WhiteLabelService
  ) {
    this.UOModel = new OrganizationUnitModel();
    this.UOController = new OrganizationUnitController(dataService);
  }

  ngOnInit(): void {
    this.Load();
  }

  async Load(tab = 1) {
    this.navigatorService.StartLoading();
    await this.navigatorService.Wait();
    this.Tab = tab;
    if (this.Tab === 2) {
      await this.LoadUO();
    }
    this.navigatorService.StopLoading();
  }

  Edit(step) {
    const data = {
      Dto: this.authenticationService.CurrentUser,
      Step: step,
    };
    this.navigatorService.ShowDialog(
      ProfileInfoComponent,
      data,
      '45%',
      'fit-content',
      '200px',
      async (response: CurrentUserModel) => {
        if (response) {
          if (response.Performed) {
            const user = this.authenticationService.CurrentUser;
            user.Name = response.Entity.Name;
            user.Surname = response.Entity.Surname;
            user.Birthdate = response.Entity.Birthdate;
            user.Phone = response.Entity.Phone;
            user.Email = response.Entity.Email;
            user.Language = response.Entity.Language;
            user.IsSetEncryptionPassword = response.Dto.IsSetEncryptionPassword;
            this.authenticationService.CurrentUser = user;
            this.navigatorService.Dictionary = new Dictionary(
              this.authenticationService.CurrentUser.Language,
              this.whiteLabelService.AppName,
              this.authenticationService.CurrentUser?.Displayname
            ).Dictionary;
            const docApplicationSidebar = new DocApplicationSidebar(
              this.navigatorService.Dictionary,
              this.authenticationService.DocAccount.Dto
            );
            this.navigatorService.DashboardMenu =
              docApplicationSidebar.GetDashboardMenu();
            this.navigatorService.DocMenu = docApplicationSidebar.GetMenu();
            this.navigatorService.SettingsMenu =
              docApplicationSidebar.GetSettingsMenu();
            this.navigatorService.UserProfileMenu =
              docApplicationSidebar.GetUserProfileMenu();
            this.navigatorService.CurrentPage =
              this.navigatorService.UserProfileMenu;
            for (const dto of this.commonService.NotificationModel.Dtos) {
              this.commonService.ParseNotification(
                dto,
                this.authenticationService.CurrentUser.Username,
                this.navigatorService.Dictionary
              );
            }
          }
        }
      }
    );
  }

  //#region Role
  async LoadUO() {
    this.UOModel.Filter = new OrganizationUnitFilter();
    this.UOModel.Filter.Trashed = false;
    this.UOModel.Filter = new OrganizationUnitFilter();
    this.UOModel.Filter.AccountId =
      this.authenticationService.DocAccount?.Dto?.Id;
    if (this.UOModel?.Filter?.AccountId > 0) {
      this.UOModel.Order = new BaseOrder();
      this.UOModel.Order.Name = 'Id';
      this.UOModel.Order.Direction = 'asc';
      this.UOModel = await this.UOController.Get(this.UOModel);
      if (this.UOModel.Performed) {
        this.UOModel.Dtos.sort((a, b) =>
          a.OfficeCode.toLowerCase() > b.OfficeCode.toLowerCase() ? 1 : -1
        );
      } else {
        this.navigatorService.ShowSnackBar(
          this.navigatorService.Dictionary?.MessageGenericError
        );
      }
    } else {
      this.UOModel.Performed = true;
      this.UOModel.Dtos = [];
    }
    this.UODataSource = new MatTableDataSource<OrganizationUnitDto>(
      this.UOModel?.Dtos
    );
    this.navigatorService.StopLoading();
  }
  //#endregion
}
