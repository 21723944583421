import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { FileManagerController } from './documentary/controllers/FileManagerController';
import { CustomFileModel } from './documentary/models/CustomFileModel';
import { FileDto, FileModel } from './documentary/models/FileModel';
import { ObjectDossierDto } from './documentary/models/ObjectDossierModel';
import { ObjectDto } from './documentary/models/ObjectModel';
import * as JSZip from 'jszip';
import { MimeTypeDto } from './documentary/models/MimeTypeModel';
import {
  AccountRole,
  Constants,
  DocumentState,
  Language,
  Pages,
} from './documentary/doc.configuration';
import { DictionaryInterface } from './dictionary/dictionary.interface';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { NavigatorService } from './navigator.services';
import { SettingsController } from './controllers/SettingsController';
import { MediaViewerComponent } from './documentary/pages/media-viewer/media-viewer.component';
import { NavigationExtras } from '@angular/router';
import { CurrentUserDto } from './models/CurrentUserModel';
import {
  DataNotification,
  NotificationDto,
  NotificationModel,
} from './models/NotificationModel';
import { EncryptComponent } from './documentary/pages/dialog-menu/encrypt/encrypt.component';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class CommonService {
  private FileManagerController: FileManagerController;
  private SettingsController: SettingsController;
  NotificationModel: NotificationModel;
  CurrentPageComponent: any;

  constructor(
    private dataService: DataService,
    private sanitizer: DomSanitizer
  ) {
    this.FileManagerController = new FileManagerController(dataService);
    this.SettingsController = new SettingsController(dataService);
  }

  IsRefresh(): boolean {
    const urlFragments = window.location.pathname.split('/');
    if (urlFragments && urlFragments?.length > 2) {
      return true;
    }
    return false;
  }

  async Download(document: ObjectDto) {
    const model = new FileModel();
    model.Dto = new FileDto();
    model.Dto.Path = document.Path;
    model.Dto.PhysicalName = document.PhysicalName;
    return await this.FileManagerController.Download(model);
  }

  async ConvertDocument(dto: ObjectDto, skip: number = 0, take: number = 20) {
    let fileModel = new FileModel();
    fileModel.Skip = skip;
    fileModel.Take = take;
    fileModel.Dto = new FileDto();
    fileModel.Dto.Id = dto.Id;
    fileModel.Dto.MimeType = dto.MimeType;
    fileModel.Dto.UoId = dto.UoId;
    fileModel.Dto.ShareToken = null;
    fileModel.Dto.Path = dto.Path;
    fileModel.Dto.PhysicalName = dto.PhysicalName;
    fileModel = await this.SettingsController.ConvertOnlyOfficeFile(fileModel);
    return fileModel;
  }

  Base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters?.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  GetExtension(physicalName: string): string {
    if (physicalName) {
      return physicalName?.substring(physicalName?.lastIndexOf('.') + 1);
    }
    return null;
  }

  GetIcon(element: ObjectDto | ObjectDossierDto) {
    if (element.Parent === null && element.Deep === null) {
      element.Icon = '../../assets/icon/uo-black.svg';
      element.ClassIcon = 'uo-icon';
    } else if (element.Parent === null && element.Deep === 0) {
      element.Icon = '../../assets/icon/tipology-black.svg';
      element.ClassIcon = 'tipology-icon';
    } else if (element.Type === 'folder') {
      element.Icon = '../../assets/icon/folder.svg';
      element.ClassIcon = 'folder-icon';
    } else {
      if (element.Crypt) {
        element.Icon = '../../assets/icon/lock.svg';
        element.ClassIcon = 'presentation';
      } else {
        const ext = this.GetExtension(element.PhysicalName);
        // presentation
        if (ext === 'ppt' || ext === 'pptx' || ext === 'odp') {
          element.Icon = '../../assets/icon/doc_presentation.svg';
          element.ClassIcon = 'presentation';
        } else if (ext === 'html') {
          element.Icon = '../../assets/icon/doc_doc.svg';
          element.ClassIcon = 'html';
        } else if (ext === 'txt') {
          element.Icon = '../../assets/icon/doc_doc.svg';
          element.ClassIcon = 'txt';
        } else if (ext === 'jpg' || ext === 'png') {
          element.Icon = '../../assets/icon/doc_doc.svg';
          element.ClassIcon = 'image';
        } else if (ext === 'xls' || ext === 'xlsx' || ext === 'ods') {
          element.Icon = '../../assets/icon/doc_doc.svg';
          element.ClassIcon = 'xls';
        } else if (ext === 'doc' || ext === 'docx' || ext === 'odt') {
          element.Icon = '../../assets/icon/doc_doc.svg';
          element.ClassIcon = 'doc';
        } else if (ext === 'pdf') {
          element.Icon = '../../assets/icon/doc_pdf.svg';
          element.ClassIcon = 'pdf';
        } else {
          element.Icon = '../../assets/icon/doc_doc.svg';
          element.ClassIcon = 'default';
        }
      }
    }
  }

  GetIconNotificationActivity(type: string) {
    let icon = null;
    switch (type) {
      case 'NEWINTITATION':
      case 'USEREDIT':
      case 'USEREDELETE':
      case 'RESETPASSWORD': {
        icon = '../../../../assets/icon/user.svg';
        break;
      }
      case 'NEWWORKFLOW':
      case 'EDITWORKFLOW':
      case 'PUBLISHWORKFLOW':
      case 'NEXTWORKFLOW':
      case 'BACKWORKFLOW': {
        icon = '../../../../assets/icon/goal-dark.svg';
        break;
      }
      case 'ARCHIVED': {
        icon = '../../../../assets/icon/archived-dark.svg';
        break;
      }
      case 'PROTOCOLLED': {
        icon = '../../../../assets/icon/protocols-dark.svg';
        break;
      }
      case 'REJECTED':
      case 'DELETEDOCUMENT': {
        icon = '../../../../assets/icon/big-doc.svg';
        break;
      }
      case 'DOSSIERCREATE':
      case 'DOSSIERUPDATE':
      case 'DOSSIERCLOSE':
      case 'DELETEDOSSIER':
      case 'ADDDOSSIER': {
        icon = '../../../../assets/icon/dossier-black.svg';
        break;
      }
      case 'NEWUO':
      case 'NEWUOUSER':
      case 'DELETEUOUSER':
      case 'UPDATEUO':
      case 'DELETEUO':
      case 'EDITUOUSERROLE': {
        icon = '../../../../assets/icon/uo-black.svg';
        break;
      }
      case 'NEWTIPOLOGY':
      case 'EDITTIPOLOGY':
      case 'DELETEUOTIPOLOGY':
      case 'DELETETIPOLOGY': {
        icon = '../../../../assets/icon/tipology-black.svg';
        break;
      }
      case 'CREATE':
      case 'UPDATE':
      case 'DELETE':
      case 'TRASHED':
      case 'RESTORE':
      case 'CREATEATTACHMENT':
      case 'DELETEATTACHMENT':
      case 'REVISION':
      case 'REVISIONED':
      case 'APPROVAL':
      case 'APPROVED': {
        icon = '../../../../../assets/icon/doc_doc.svg';
        break;
      }
      case 'FOLDER': {
        icon = '../../../../../assets/icon/folder.svg';
        break;
      }
    }
    return icon;
  }

  CustomDownloadFile(blob: Blob, filename: string, extension: string) {
    const url = URL.createObjectURL(blob);
    const dwldLink = document.createElement('a');
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + extension);
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(data: any[], headerList: string[], writeheader = false) {
    const array = typeof data !== 'object' ? JSON.parse(data) : data;
    let dto = '';
    let headerdto = '';

    if (writeheader) {
      for (const index of Object.keys(headerList)) {
        headerdto += headerList[index] + ';';
      }
      headerdto = headerdto.slice(0, -1);
      dto += headerdto + '\r\n';
    }

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < array?.length; i++) {
      let line = '';
      for (const index of Object.keys(headerList)) {
        const head = headerList[index];
        if (line !== '') {
          line += ';';
        }
        line += array[i][head];
      }
      dto += line + '\r\n';
    }
    return dto;
  }

  CreateZip(fileToAdd: Array<CustomFileModel>, zipFileName: string) {
    // const zip = new JSZip();
    // for (const file of fileToAdd) {
    //   zip.file(file.Name, file.Content);
    // }
    // zip.generateAsync({ type: 'blob' }).then(blob => {
    //   this.CustomDownloadFile(blob, zipFileName, '.zip');
    // });
  }

  SetMimeTypeChipStyle(dto: MimeTypeDto) {
    const ext = dto.Extension;
    if (ext === '.pdf') {
      dto.ChipIcon = 'pdf-chip-white';
      dto.ChipBackgroundColor = '#c50866';
    } else if (ext === '.doc' || ext === '.docx') {
      dto.ChipIcon = 'docx-chip-white';
      dto.ChipBackgroundColor = '#0671f2';
    } else if (ext === '.ppt' || ext === '.pptx') {
      dto.ChipIcon = 'pptx-chip-white';
      dto.ChipBackgroundColor = '#ec6200';
    } else {
      dto.ChipIcon = 'docx-chip-white';
      dto.ChipBackgroundColor = '#0671f2';
    }
  }

  GetDisplayState(dictionary: DictionaryInterface, state: string) {
    if (state) {
      if (state === DocumentState.PROGRESS) {
        return dictionary.StateInProgress;
      } else if (state === DocumentState.REVISION) {
        return dictionary.StateInRevision;
      } else if (state === DocumentState.REVISIONED) {
        return dictionary.StateRevisioned;
      } else if (state === DocumentState.APPROVAL) {
        return dictionary.StateInApproval;
      } else if (state === DocumentState.APPROVED) {
        return dictionary.StateApproved;
      } else if (state === DocumentState.ARCHIVED) {
        return dictionary.StateArchived;
      } else if (state === DocumentState.EDIT) {
        return dictionary.StateEdit;
      } else if (state === DocumentState.REJECTED) {
        return dictionary.StateReject;
      } else if (state === DocumentState.PROTOCOLLED) {
        return dictionary.StateProtocolled;
      }
      return state;
    }
    return Constants.ND;
  }

  MatchValidator(controlName: string, checkControlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const checkControl = controls.get(checkControlName);
      if (checkControl.errors) {
        return null;
      }
      if (control.value !== checkControl.value) {
        controls.get(checkControlName).setErrors({ matching: true });
        return { matching: true };
      } else {
        return null;
      }
    };
  }

  SidebarMenuClick(page, navigatorService, extraPapams?: NavigationExtras) {
    const find = this.GetMenuItem(page, navigatorService);
    if (find) {
      const menuItem = find?.Child ? find.Child : find.Parent;
      if (menuItem) {
        navigatorService.DashboardMenu.Selected = false;
        navigatorService.UserProfileMenu.map((q) => (q.Selected = false));
        if (navigatorService.SettingsMenu) {
          navigatorService.SettingsMenu.Selected = false;
        }
        navigatorService.DocMenu?.map((itemMenu) => {
          itemMenu.Selected = false;
          if (itemMenu.Childs) {
            itemMenu.Childs.map((i) => (i.Selected = false));
          }
        });
        if (menuItem.Visible) {
          find.Parent.Expanded = !find.Parent.Expanded
            ? find?.Child !== null
            : find.Parent.Expanded;
          menuItem.Selected = !menuItem.Selected;
        } else {
          find.Parent.Selected = true;
        }
        navigatorService.CurrentPage = menuItem;
        navigatorService.GoTo(menuItem.Link, extraPapams);
      }
    }
  }

  GetMenuItem(page: string, navigatorService: NavigatorService) {
    for (const itemMenu of navigatorService.DocMenu) {
      if (itemMenu.Link === page) {
        return { Parent: itemMenu, Child: null };
      } else {
        if (itemMenu.Childs) {
          const find = itemMenu.Childs.find((i) => i.Link === page);
          if (find) {
            return { Parent: itemMenu, Child: find };
          }
        }
      }
    }
    if (navigatorService.DashboardMenu.Link === page) {
      return { Parent: navigatorService.DashboardMenu, Child: null };
    }
    if (navigatorService.SettingsMenu.Link === page) {
      return { Parent: navigatorService.SettingsMenu, Child: null };
    }
    return null;
  }

  async OpenObject(dto: ObjectDto, navigatorService: NavigatorService) {
    if (dto.Type === 'file' && dto.Crypt) {
      const data = { Dto: dto, SoftDecrypt: true };
      navigatorService.ShowDialog(
        EncryptComponent,
        data,
        '45%',
        'fit-content',
        '200px',
        async (response) => {
          if (response?.Performed) {
            dto = response.Dto;
            await this.Open(dto, navigatorService);
          }
        }
      );
    } else {
      this.Open(dto, navigatorService);
    }
  }

  private async Open(dto: ObjectDto, navigatorService: NavigatorService) {
    navigatorService.StartLoading();
    dto.Selected = true;
    const extension = this.GetExtension(dto.PhysicalName);
    if (extension !== 'p7m' && dto.MimeType) {
      if (
        dto?.MimeType?.includes('image') ||
        dto.MimeType?.includes('video') ||
        dto.MimeType?.includes('audio')
      ) {
        let model = new FileModel();
        model.Dto = new FileDto();
        model.Dto.Path = dto.Path;
        model.Dto.PhysicalName = dto.PhysicalName;
        model.Dto.MimeType = dto.MimeType;
        model = await this.FileManagerController.Download(model);
        if (model?.Performed) {
          const dataMedia = {
            Stream: model.Dto.File,
            Extension: extension,
            FileName:
              dto.Name ??
              dto.PhysicalName?.replace(
                '.' + this.GetExtension(dto.PhysicalName),
                ''
              ),
            MimeType: model.Dto.MimeType,
          };
          const width = dto.MimeType?.includes('audio') ? '30%' : '70%';
          const height = dto.MimeType?.includes('audio') ? '15%' : '85%';
          navigatorService.ShowDialog(
            MediaViewerComponent,
            dataMedia,
            width,
            height,
            '200px'
          );
        }
      } else {
        let queryParams: NavigationExtras = null;
        if (dto.Type === 'file') {
          queryParams = {
            queryParams: {
              id: dto.Id,
              uo: dto.UoId,
            },
          };
        } else if (dto.Type === 'attachment') {
          queryParams = {
            queryParams: {
              id: dto.Id,
              type: dto.Type,
              uo: dto.UoId,
            },
          };
        }
        navigatorService.GoTo(Pages.OnlyOffice, queryParams, 'v2/', true);
      }
    } else {
      await this.ExtractP7M(dto, navigatorService);
    }
    navigatorService.StopLoading();
  }

  async ExtractP7M(dto: ObjectDto, navigatorService: NavigatorService) {
    const fileModel = new FileModel();
    fileModel.Dto = new FileDto();
    fileModel.Dto.Id = dto.Id;
    fileModel.Dto.MimeType = dto.MimeType;
    fileModel.Dto.PhysicalName = dto.PhysicalName;
    fileModel.Dto.Path = Constants.AppId + '/' + dto.Path;
    fileModel.Dto.OriginalExtension = dto.OriginalExtension;
    const response = await this.SettingsController.ExtractP7M(fileModel);
    if (response.Performed) {
      if (response.Dto['onlyOffice']) {
        let queryParams: NavigationExtras = null;
        queryParams = {
          queryParams: {
            id: dto.Id,
            uo: dto.UoId,
          },
        };
        navigatorService.GoTo(Pages.OnlyOffice, queryParams, 'v2/', true);
      } else if (
        response.Dto['originalMimeType'].includes('image') ||
        response.Dto['originalMimeType'].includes('video')
      ) {
        const dataMedia = {
          Stream: response.Dto['fileContent'],
          Extension: this.GetExtension(response.Dto['originalFileName']),
          FileName: response.Dto['originalFileName'],
          MimeType: response.Dto['originalMimeType'],
        };
        navigatorService.ShowDialog(
          MediaViewerComponent,
          dataMedia,
          '70%',
          '85%',
          '200px'
        );
      } else {
        const blob = this.Base64toBlob(
          response.Dto['fileContent'],
          response.Dto['originalMimeType']
        );
        const element = document.createElement('a');
        element.href = URL.createObjectURL(blob);
        element.download = dto.Name;
        // start download
        element.click();
      }
    }
  }

  GetUserRole(user: CurrentUserDto) {
    if (user) {
      const find = user.Groups.find((g) => g.Name === 'admin');
      if (user.IsOwner && find) {
        return 'Owner, Admin';
      } else if (find) {
        return 'Admin';
      } else if (user.IsOwner) {
        return 'Owner';
      } else {
        return 'Worker';
      }
    }
    return null;
  }

  GetDisplayDocsRole(role: string, dictionary: DictionaryInterface) {
    if (role) {
      if (role === AccountRole.APPROVER) {
        return dictionary.Approver;
      } else if (role === AccountRole.ADMIN) {
        return dictionary.Admin;
      } else if (role === AccountRole.REVISOR) {
        return dictionary.Revisor;
      } else {
        return dictionary.Operator;
      }
    }
    return null;
  }

  BlobToBase64(blob, callback) {
    const fileReader = new FileReader();
    fileReader.onload = async (fileLoadedEvent) => {
      const base64 = fileLoadedEvent.target.result;
      if (callback) {
        callback(base64);
        return;
      }
    };
    fileReader.readAsDataURL(blob);
  }

  ParseNotification(
    dto: NotificationDto,
    username: string,
    dictionary: DictionaryInterface
  ) {
    if (dto?.data) {
      switch (dto.data.Type) {
        case 'NEWINTITATION': {
          let message = null;
          if (dto.data.SenderUsername === username) {
            message = dictionary.NotificationNewInvitationFirstPerson;
          } else {
            message = dictionary.NotificationNewInvitationThirdPerson;
          }
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleNewInvitation;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'USEREDIT': {
          let message = null;
          if (dto.data.SenderUsername === username) {
            message = dictionary.NotificationUserEditFirstPerson;
          } else {
            message = dictionary.NotificationUserEditThirdPerson;
          }
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleUserEdit;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'USEREDELETE': {
          let message = null;
          if (dto.data.SenderUsername === username) {
            message = dictionary.NotificationUserDeleteFirstPerson;
          } else {
            message = dictionary.NotificationUserDeleteThirdPerson;
          }
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleUserDelete;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'RESETPASSWORD': {
          let message = null;
          if (dto.data.SenderUsername === username) {
            message = dictionary.NotificationResetPasswordFirstPerson;
          } else {
            message = dictionary.NotificationResetPasswordThirdPerson;
          }
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleResetPassword;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'NEWWORKFLOW': {
          let message = null;
          if (dto.data.SenderUsername === username) {
            message = dictionary.NotificationNewWorkflowFirstPerson;
          } else {
            message = dictionary.NotificationNewWorkflowThirdPerson;
          }
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleNewWorkflow;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'EDITWORKFLOW': {
          let message = null;
          if (dto.data.SenderUsername === username) {
            message = dictionary.NotificationEditWorkflowFirstPerson;
          } else {
            message = dictionary.NotificationEditWorkflowThirdPerson;
          }
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleEditWorkflow;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'PUBLISHWORKFLOW': {
          let message = null;
          if (dto.data.SenderUsername === username) {
            message = dictionary.NotificationPublishWorkflowFirstPerson;
          } else {
            message = dictionary.NotificationPublishWorkflowThirdPerson;
          }
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitlePublishWorkflow;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'NEXTWORKFLOW': {
          let message = null;
          if (dto.data.SenderUsername === username) {
            message = dictionary.NotificationNextWorkflowFirstPerson;
          } else {
            message = dictionary.NotificationNextWorkflowThirdPerson;
          }
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleNextWorkflow;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'BACKWORKFLOW': {
          let message = null;
          if (dto.data.SenderUsername === username) {
            message = dictionary.NotificationBackWorkflowFirstPerson;
          } else {
            message = dictionary.NotificationBackWorkflowThirdPerson;
          }
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleBackWorkflow;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'ARCHIVED': {
          let message = null;
          if (dto.data.SenderUsername === username) {
            message = dictionary.NotificationArchivedDocumentFirstPerson;
          } else {
            message = dictionary.NotificationArchivedDocumentThirdPerson;
          }
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleArchivedDocument;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'PROTOCOLLED': {
          let message = null;
          if (dto.data.SenderUsername === username) {
            message = dictionary.NotificationProtocolledDocumentFirstPerson;
          } else {
            message = dictionary.NotificationProtocolledDocumentThirdPerson;
          }
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleProtocolledDocument;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'REJECTED': {
          let message = null;
          if (dto.data.SenderUsername === username) {
            message = dictionary.NotificationRejectedDocumentFirstPerson;
          } else {
            message = dictionary.NotificationRejectedDocumentThirdPerson;
          }
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleRejectedDocument;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'DELETEDOCUMENT': {
          let message = null;
          if (dto.data.SenderUsername === username) {
            message = dictionary.NotificationDeletedDocumentFirstPerson;
          } else {
            message = dictionary.NotificationDeletedDocumentThirdPerson;
          }
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleDeletedDocument;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'DOSSIERCREATE': {
          let message = null;
          if (dto.data.SenderUsername === username) {
            message = dictionary.NotificationNewDossierFirstPerson;
          } else {
            message = dictionary.NotificationNewDossierThirdPerson;
          }
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleNewDossier;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'DOSSIERUPDATE': {
          let message = null;
          if (dto.data.SenderUsername === username) {
            message = dictionary.NotificationUpdateDossierFirstPerson;
          } else {
            message = dictionary.NotificationUpdateDossierThirdPerson;
          }
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleUpdateDossier;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'DOSSIERCLOSE': {
          let message = null;
          if (dto.data.SenderUsername === username) {
            message = dictionary.NotificationArchivedDossierFirstPerson;
          } else {
            message = dictionary.NotificationArchivedDossierThirdPerson;
          }
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleArchivedDossier;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'DELETEDOSSIER': {
          let message = null;
          if (dto.data.SenderUsername === username) {
            message = dictionary.NotificationDeletedDossierFirstPerson;
          } else {
            message = dictionary.NotificationDeletedDossierThirdPerson;
          }
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleDeletedDossier;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'ADDDOSSIER': {
          let message = null;
          if (dto.data.SenderUsername === username) {
            message = dictionary.NotificationAddedToDossierFirstPerson;
          } else {
            message = dictionary.NotificationAddedToDossierThirdPerson;
          }
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleAddedToDossier;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'NEWUO': {
          const message = dictionary.NotificationNewUoThirdPerson;
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleNewUo;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'NEWUOUSER': {
          const message = dictionary.NotificationNewUoUserThirdPerson;
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleNewUoUser;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'DELETEUOUSER': {
          const message = dictionary.NotificationDeleteUoUserThirdPerson;
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleDeleteUoUser;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'UPDATEUO': {
          const message = dictionary.NotificationUpdateUoThirdPerson;
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleUpdateUo;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'DELETEUO': {
          let message = null;
          if (dto.data.Role) {
            message = dictionary.NotificationDeletedUoFirstPerson;
          } else {
            message = dictionary.NotificationDeletedUoThirdPerson;
          }
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleDeletedUo;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'EDITUOUSERROLE': {
          const message = dictionary.NotificationEditUoUserRoleThirdPerson;
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleEditUoUserRole;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'NEWTIPOLOGY': {
          let message = null;
          if (dto.data.SenderUsername === username) {
            message = dictionary.NotificationNewTipologyFirstPerson;
          } else {
            message = dictionary.NotificationNewTipologyThirdPerson;
          }
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleNewTipology;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'EDITTIPOLOGY': {
          let message = null;
          if (dto.data.SenderUsername === username) {
            message = dictionary.NotificationEditTipologyFirstPerson;
          } else {
            message = dictionary.NotificationEditTipologyThirdPerson;
          }
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleEditTipology;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'DELETEUOTIPOLOGY': {
          const message = dictionary.NotificationDeleteUoTipologyThirdPerson;
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleDeleteUoTipology;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
        case 'DELETETIPOLOGY': {
          let message = null;
          if (dto.data.SenderUsername === username) {
            message = dictionary.NotificationDeleteTipologyFirstPerson;
          } else {
            message = dictionary.NotificationDeleteTipologyThirdPerson;
          }
          dto.Icon = this.GetIconNotificationActivity(dto.data.Type);
          dto.Subject = dictionary.NotificationTitleDeleteTipology;
          dto.Message = this.BuildNotificationMessage(dto.data, message);
          break;
        }
      }
    }
  }

  BuildNotificationMessage(data: DataNotification, message: string) {
    const properties = Object.keys(data);
    for (const prop of properties) {
      message = message.replace(':' + prop, data[prop]);
    }
    return message;
  }

  GetLanguage(culture: string, dictionary: DictionaryInterface) {
    if (culture === Language.IT) {
      return dictionary?.Italian;
    } else if (culture === Language.EN) {
      return dictionary?.English;
    }
    return dictionary?.Italian;
  }

  RemoveDuplicate(model: any) {
    model.Dtos = model.Dtos.filter((a, i) => {
      return (
        model.Dtos.findIndex((x) => {
          return x.Id === a.Id;
        }) === i
      );
    });
    return model;
  }

  async GetBlobUrl(file: any): Promise<any> {
    const newResponse = new Response(file);
    const blob = await newResponse.blob();
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      URL.createObjectURL(blob)
    );
  }
}
