import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '../../../../data.service';
import { GenericConfirmDialogComponent } from '../../dialog-menu/generic-confirm-dialog/generic-confirm-dialog.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { SearchComponent } from '../../../custom-components/search/search.component';
import { PagerComponent } from '../../../custom-components/pager/pager.component';
import {
  ObjectDto,
  ObjectFilter,
  ObjectModel,
} from '../../../models/ObjectModel';
import { ObjectController } from '../../../controllers/ObjectController';
import {
  Constants,
  DocumentState,
  FileMenu,
  FilesMenu,
  GenericDialogAction,
} from '../../../doc.configuration';
import { CommonService } from '../../../../common.service';
import { NavigatorService } from '../../../../navigator.services';
import { AuthService } from '../../../../auth.service';
import { BaseOrder } from '../../../../models/BaseModel';
import { WorkflowUserDto } from '../../../../workflow/models/WorkflowUserModel';
import { GenericDialogModel } from '../../../models/GenericDialogModel';

@Component({
  selector: 'app-trashed-view',
  templateUrl: './trashed-view.component.html',
  styleUrls: ['./trashed-view.component.scss'],
})
export class TrashedViewComponent implements OnInit {
  @ViewChild('search') SearchComponent: SearchComponent;
  @ViewChild(MatMenuTrigger) multiMoreActionTrigger: MatMenuTrigger;
  @ViewChild('pager') Pager: PagerComponent;
  Model: ObjectModel;
  Controller: ObjectController;
  DataSource: MatTableDataSource<ObjectDto>;
  Pages: Array<number>;
  CurrentPage = 1;
  CurrentFolder: ObjectDto;
  ND = Constants.ND.toString();
  FileMenu: Array<any>;
  FilesMenu: Array<any>;
  Tab = 1;
  CountAllObject: number = null;
  constructor(
    dataService: DataService,
    public navigatorService: NavigatorService,
    public authenticationService: AuthService,
    public commonService: CommonService
  ) {
    commonService.CurrentPageComponent = this;

    this.Model = new ObjectModel();
    this.Controller = new ObjectController(dataService);
  }

  ngOnInit(): void {
    if (!this.navigatorService.Loading) {
      this.Load();
    }
  }

  async Load(tab = 1) {
    this.Tab = tab;
    this.navigatorService.StartLoading();
    await this.navigatorService.Wait();
    this.Model = new ObjectModel();
    this.SearchComponent?.ClearInputValue(false);
    if (this.CountAllObject === null) {
      this.CountAllObject = await this.CountAll();
      this.Tab = this.CountAllObject > 0 ? 2 : 1;
    }
    if (this.navigatorService.NotificationTargetId > 0) {
      this.Tab = 1;
      this.navigatorService.ObjectDto = await this.ReadObject(
        this.navigatorService.NotificationTargetId
      );
      if (this.navigatorService.ObjectDto) {
        this.navigatorService.ObjectDto.IsRedirect = true;
      } else {
        this.navigatorService.ShowSnackBar(
          this.navigatorService.Dictionary?.DocumentNotFound
        );
      }
      this.navigatorService.NotificationTargetId = null;
    }
    if (
      this.navigatorService.ObjectDto &&
      this.navigatorService.ObjectDto.IsRedirect
    ) {
      this.CurrentFolder = this.navigatorService.ObjectDto;
      this.Model.Performed = true;
      this.Model.Dtos.push(this.CurrentFolder);
      this.Model.Count = 1;
      this.navigatorService.ObjectDto = null;
      this.Paging();
    } else {
      await this.GetObjects();
    }
    this.navigatorService.StopLoading();
  }

  async CountAll() {
    return await this.Controller.CountAll(
      this.authenticationService.DocAccount.Dto.Id,
      null,
      true
    );
  }

  async GetObjects(page = 1) {
    this.Model.Search = null;
    this.Model.Filter = new ObjectFilter();
    this.Model.Filter.RoleName = this.Tab === 2 ? 'Admin' : null;
    this.Model.Filter.Creator =
      this.Tab === 1 ? this.authenticationService.CurrentUser.Username : null;
    this.Model.Filter.AccountId = this.authenticationService.DocAccount.Dto.Id;
    this.Model.Filter.Trashed = true;
    this.Model.Search = this.SearchComponent?.GetSearchValue();
    this.Model.Order = new BaseOrder();
    this.Model.Order.Name = 'Id';
    this.Model.Order.Direction = 'desc';
    this.Model = await this.Controller.Load(this.Model);
    this.Paging(page);
  }

  CheckRow(event: any, dto: ObjectDto) {
    if (dto && dto.Id > 0) {
      dto.Checked = event.checked;
    }
  }

  SelectRow(dto: ObjectDto) {
    if (dto && dto.Id > 0) {
      this.Model.Dtos.map((o) => (o.Selected = false));
      dto.Selected = true;
    }
  }

  async ReadObject(parent) {
    let model = new ObjectModel();
    model.Filter = new ObjectFilter();
    model.Filter.AccountId = this.authenticationService.DocAccount.Dto.Id;
    model.Filter.Id = parent;
    model = await this.Controller.Read(model);
    return model?.Dto ?? null;
  }

  async Search(searchText: string) {
    this.navigatorService.StartLoading();
    this.Model.Skip = 0;
    await this.GetObjects();
    this.navigatorService.StopLoading();
  }

  async BreadcrumbBack() {
    this.navigatorService.StartLoading();
    this.Model.Skip = 0;
    let folder = this.CurrentFolder;
    if (this.CurrentFolder?.Deep > 1) {
      this.CurrentFolder = await this.ReadObject(this.CurrentFolder.Parent);
      folder = this.CurrentFolder;
    } else {
      this.CurrentFolder = null;
      folder = null;
    }
    await this.GetObjects(this.CurrentPage);
    this.navigatorService.StopLoading();
  }

  HeaderCheckbox(event: any) {
    if (event) {
      this.Model?.Dtos?.map((o) => (o.Checked = event.checked));
    }
  }

  IsAllSelected() {
    const selection = new SelectionModel<ObjectDto>(
      true,
      this.Model?.Dtos?.filter((t) => t.Checked)
    );
    if (selection.selected.length > 0) {
      return selection.selected.length === this.Model?.Dtos?.length;
    }
    return false;
  }

  // IsRowsChecked() {
  //   return this.Model?.Dtos?.filter(o => o.Checked === true)?.length > 0;

  // }

  DisabledMenuChecked() {
    return this.Model?.Dtos?.filter((o) => o.Checked).length <= 0;
  }

  RefreshTable(dtos: Array<ObjectDto>) {
    for (const dto of dtos) {
      if (!dto.ClassIcon && !dto.Icon) {
        this.commonService.GetIcon(dto);
      }

      if (
        dto?.WorkflowData?.WorkflowSteps &&
        dto?.WorkflowData?.WorkflowSteps?.length > 0
      ) {
        dto.Team = new Array<WorkflowUserDto>();
        for (const step of dto?.WorkflowData?.WorkflowSteps) {
          for (const user of step.WorkflowStepUsers) {
            const find = dto.Team.find((u) => u.AccountId === user.AccountId);
            if (!find) {
              dto.Team.push(user);
            }
          }
        }
      }
    }
    this.DataSource = null;
    this.DataSource = new MatTableDataSource<ObjectDto>(dtos);
  }

  Paging(page: number = 1) {
    this.Pager.Model = this.Model;
    this.Pager.Paging(page);
    this.RefreshTable(this.Model.Dtos);
  }

  async PagingChange(page) {
    this.navigatorService.StartLoading();
    await this.GetObjects(page);
    this.navigatorService.StopLoading();
  }

  GetMenuFiles() {
    const dtos = this.Model?.Dtos?.filter((t) => t.Checked === true);
    const menuDelete =
      dtos.filter(
        (q) =>
          q.State !== DocumentState.PROGRESS &&
          q.State !== DocumentState.REJECTED
      )?.length <= 0;
    const menuSign = false;
    const menuCollate =
      dtos.filter(
        (q) =>
          q.State !== DocumentState.PROTOCOLLED &&
          q.State !== DocumentState.ARCHIVED
      )?.length <= 0;
    const menu = new FilesMenu(
      this.navigatorService.Dictionary,
      menuDelete,
      menuSign,
      menuCollate,
      true
    );
    this.FilesMenu = menu.GetMenu();
  }

  GetMenuFile(dto: ObjectDto) {
    const menu = new FileMenu(
      this.navigatorService.Dictionary,
      dto,
      this.authenticationService.DocAccount?.Dto,
      true
    );
    this.FileMenu = menu.GetMenu();
  }

  GetMoreActionFilesDisabled() {
    return this.Model?.Dtos?.filter((t) => t.Checked)?.length <= 0;
  }

  ActionMenu(action: string, dto: ObjectDto | Array<ObjectDto> = null) {
    if (action === 'permanent_delete') {
      if (dto && !Array.isArray(dto)) {
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Name =
          dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
        data.Title = this.navigatorService.Dictionary?.Delete;
        data.Message =
          this.navigatorService.Dictionary?.AreYouSureWantMsg +
          ' ' +
          this.navigatorService.Dictionary?.PermanentlyDelete +
          '<br>' +
          dto.Name +
          '.' +
          this.commonService.GetExtension(dto.PhysicalName) +
          '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Delete;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(
          GenericConfirmDialogComponent,
          data,
          '45%',
          'fit-content',
          '200px',
          async (performed) => {
            if (performed === GenericDialogAction.CONFIRM) {
              this.navigatorService.StartLoading();
              let model = new ObjectModel();
              model.Filter = new ObjectFilter();
              model.Filter.Trashed = true;
              model.Filter.Id = dto.Id;
              model = await this.Controller.Delete(model);
              if (model?.Performed) {
                this.navigatorService.ShowSnackBar(
                  this.navigatorService.Dictionary?.DeleteSuccess
                );
                await this.GetObjects(this.CurrentPage);
              } else {
                this.navigatorService.ShowSnackBar(
                  this.navigatorService.Dictionary?.MessageGenericError
                );
              }
            }
            this.navigatorService.StopLoading();
          }
        );
      } else if (!dto) {
        const dtos = this.Model?.Dtos?.filter((o) => o.Checked === true);
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Title = this.navigatorService.Dictionary?.Delete;
        data.Message =
          this.navigatorService.Dictionary?.AreYouSureWantMsg +
          ' ' +
          this.navigatorService.Dictionary?.PermanentlyDelete +
          '<br>' +
          this.navigatorService.Dictionary?.AllFileSelected +
          '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Delete;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(
          GenericConfirmDialogComponent,
          data,
          '45%',
          'fit-content',
          '200px',
          async (performed) => {
            if (performed === GenericDialogAction.CONFIRM) {
              this.navigatorService.StartLoading();
              let model = new ObjectModel();
              model.Filter = new ObjectFilter();
              model.Filter.Trashed = true;
              model.Filter.Id = dtos.map((o) => o.Id);
              model = await this.Controller.Delete(model);
              if (model?.Performed) {
                this.navigatorService.ShowSnackBar(
                  this.navigatorService.Dictionary?.DeleteSuccess,
                  data.Name
                );
                await this.GetObjects(this.CurrentPage);
              } else {
                this.navigatorService.ShowSnackBar(
                  this.navigatorService.Dictionary?.MessageGenericError
                );
              }
            }
            this.navigatorService.StopLoading();
          }
        );
      }
    } else if (action === 'restore') {
      if (dto && !Array.isArray(dto)) {
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Name =
          dto.Name + '.' + this.commonService.GetExtension(dto.PhysicalName);
        data.Title = this.navigatorService.Dictionary?.Restore;
        data.Message =
          this.navigatorService.Dictionary?.AreYouSureWantMsg +
          ' ' +
          this.navigatorService.Dictionary?.ToRestore +
          '<br>' +
          dto.Name +
          '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Restore;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(
          GenericConfirmDialogComponent,
          data,
          '45%',
          'fit-content',
          '200px',
          async (performed) => {
            if (performed === GenericDialogAction.CONFIRM) {
              this.navigatorService.StartLoading();
              let model = new ObjectModel();
              dto.Trashed = false;
              model.Dto = dto;
              model.Action = 'RESTORE';
              model.UpdateProperties = ['Trashed'];
              model = await this.Controller.CreateOrUpdate(model);
              if (model?.Performed) {
                this.navigatorService.ShowSnackBar(
                  this.navigatorService.Dictionary?.RestoreSuccess,
                  data.Name
                );
                await this.GetObjects(this.CurrentPage);
              } else {
                this.navigatorService.ShowSnackBar(
                  this.navigatorService.Dictionary?.MessageGenericError
                );
              }
            }
            this.navigatorService.StopLoading();
          }
        );
      } else if (!dto) {
        const dtos = this.Model?.Dtos?.filter((o) => o.Checked === true);
        const data = new GenericDialogModel();
        data.Icon = '../../../../../assets/icon/big-doc.svg';
        data.Title = this.navigatorService.Dictionary?.Restore;
        data.Message =
          this.navigatorService.Dictionary?.AreYouSureWantMsg +
          ' ' +
          this.navigatorService.Dictionary?.ToRestore +
          '<br>' +
          this.navigatorService.Dictionary?.AllFileSelected +
          '?';
        data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Restore;
        data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
        this.navigatorService.ShowDialog(
          GenericConfirmDialogComponent,
          data,
          '45%',
          'fit-content',
          '200px',
          async (performed) => {
            if (performed === GenericDialogAction.CONFIRM) {
              this.navigatorService.StartLoading();
              let model = new ObjectModel();
              dtos.map((o) => (o.Trashed = false));
              model.Dtos = dtos;
              model.Action = 'RESTORE';
              model.UpdateProperties = ['Trashed'];
              model = await this.Controller.CreateOrUpdate(model);
              if (model?.Performed) {
                this.navigatorService.ShowSnackBar(
                  this.navigatorService.Dictionary?.RestoreSuccess
                );
                await this.GetObjects(this.CurrentPage);
              } else {
                this.navigatorService.ShowSnackBar(
                  this.navigatorService.Dictionary?.MessageGenericError
                );
              }
            }
            this.navigatorService.StopLoading();
          }
        );
      }
    }
  }
}
