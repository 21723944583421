<mat-form-field appearance="fill"  [ngClass]="Location ==='menu'?'form-field-menu':'form-field'">
  <mat-label class="placeholder">{{Placeholder}}</mat-label>
  <input *ngIf="!ReadOnly" matInput class="input-text" [value]='Value' [matDatepicker]="picker"
    (dateInput)="AddEvent('input', $event)" (dateChange)="AddEvent('change', $event)" autocomplete="off"
    [required]="Required" [matDatepickerFilter]="DatepickerFilter">
  <input *ngIf="ReadOnly" matInput class="input-text" [value]='Value' [matDatepicker]="picker"
    (dateInput)="AddEvent('input', $event)" (dateChange)="AddEvent('change', $event)" autocomplete="off"
    [required]="Required" [matDatepickerFilter]="DatepickerFilter" readonly>
  <mat-datepicker-toggle matSuffix [for]="picker">
    <img matDatepickerToggleIcon src="../../../../assets/icon/calendar.svg">
  </mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
