import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../../data.service';
import { OrganizationUnitComponent } from '../../dialog-menu/organization-unit/organization-unit.component';
import { TreeComponent } from '../../../custom-components/tree/tree.component';
import {
  OrganizationUnitDto,
  OrganizationUnitFilter,
  OrganizationUnitModel,
} from '../../../models/OrganizationUnitModel';
import { OrganizationUnitController } from '../../../controllers/OrganizationUnitController';
import { AccountController } from '../../../controllers/AccountController';
import { AccountRoleController } from '../../../controllers/AccountRoleController';
import { AccountRoleDto } from '../../../models/AccountRoleModel';
import { NavigatorService } from '../../../../navigator.services';
import { CommonService } from '../../../../common.service';
import { AuthService } from '../../../../auth.service';
import { BaseOrder } from '../../../../models/BaseModel';
import { PageAction } from '../../../doc.configuration';
import { AccountDto } from '../../../models/AccountModel';

@Component({
  selector: 'app-uo',
  templateUrl: './organization-unit-view.component.html',
  styleUrls: ['./organization-unit-view.component.scss'],
})
export class OrganizationUnitViewComponent implements OnInit {
  @ViewChild('uotree') UoTree: TreeComponent;

  Model: OrganizationUnitModel;
  Controller: OrganizationUnitController;
  AccountController: AccountController;
  AccountRoleController: AccountRoleController;
  DataSource = [];
  ResponsibleRoleId: number;
  AllAccountRole: Array<AccountRoleDto>;
  FilteredUo: Array<OrganizationUnitDto>;
  SortAscOfficeCode = true;
  SortAscName = true;
  Tab = 1;
  constructor(
    dataService: DataService,
    public navigatorService: NavigatorService,
    private commonService: CommonService,
    public authenticationService: AuthService
  ) {
    commonService.CurrentPageComponent = this;
    this.Model = new OrganizationUnitModel();
    this.Controller = new OrganizationUnitController(dataService);
    this.AccountController = new AccountController(dataService);
    this.AccountRoleController = new AccountRoleController(dataService);
    this.AllAccountRole = new Array<AccountRoleDto>();
    this.FilteredUo = new Array<OrganizationUnitDto>();
  }

  ngOnInit() {
    if (!this.navigatorService.Loading) {
      this.Load();
    }
  }

  async Load(tab = 1) {
    this.Model = new OrganizationUnitModel();
    this.navigatorService.StartLoading();
    await this.navigatorService.Wait();
    this.Tab =
      this.navigatorService.NotificationTargetId &&
      this.authenticationService.CurrentUser.IsAdmin
        ? 2
        : tab;
    this.Model.Filter = new OrganizationUnitFilter();
    this.Model.Filter.Trashed = false;
    if (this.Tab === 1) {
      this.Model.Filter = new OrganizationUnitFilter();
      this.Model.Filter.AccountId =
        this.authenticationService.DocAccount?.Dto?.Id;
    }
    if (
      (this.Model?.Filter?.AccountId > 0 && this.Tab === 1) ||
      this.Tab === 2
    ) {
      this.Model.Order = new BaseOrder();
      this.Model.Order.Name = 'Id';
      this.Model.Order.Direction = 'asc';
      this.Model = await this.Controller.GetHierarchy(this.Model);
      if (this.Model.Performed) {
        this.FilteredUo = Object.assign([], this.Model.Dtos);
        this.FilteredUo?.sort((a, b) =>
          a.OfficeCode.toLowerCase() > b.OfficeCode.toLowerCase() ? 1 : -1
        );
        if (this.navigatorService.NotificationTargetId > 0) {
          const targetId = this.navigatorService.NotificationTargetId;
          this.navigatorService.NotificationTargetId = null;
          await this.ShowTargetNotification(targetId);
        }
      } else {
        this.navigatorService.ShowSnackBar(
          this.navigatorService.Dictionary?.MessageGenericError
        );
      }
    } else {
      this.Model.Performed = true;
      this.Model.Dtos = [];
      this.FilteredUo = [];
    }
    this.navigatorService.StopLoading();
  }

  async ShowTargetNotification(targetId: number) {
    let model = new OrganizationUnitModel();
    model.Filter = new OrganizationUnitFilter();
    model.Filter.Trashed = false;
    model.Filter.Id = targetId;
    model = await this.Controller.Read(model);
    if (model.Performed && model.Dto) {
      await this.authenticationService.Initialize();
      const path = this.GetBranch(model.Dto, this.FilteredUo);
      if (path && path?.length > 0) {
        for (const depth of path) {
          this.UoTree.Expand(depth, this.UoTree.GetIndex(depth));
        }
      } else {
        this.navigatorService.ShowSnackBar(
          this.navigatorService.Dictionary?.UoNotFound,
          model.Dto?.Name
        );
      }
    } else {
      this.navigatorService.ShowSnackBar(
        this.navigatorService.Dictionary?.UoNotFound
      );
    }
  }

  Search(
    searchValue: string,
    dtos: OrganizationUnitDto[],
    recursiveStep = false
  ) {
    if (searchValue) {
      if (!recursiveStep) {
        this.FilteredUo = [];
      }
      for (const dto of dtos) {
        if (
          dto.Name.toLowerCase().includes(searchValue.toLowerCase()) ||
          dto.OfficeCode.toLowerCase().includes(searchValue.toLowerCase())
        ) {
          this.FilteredUo.push(dto);
        }
        if (dto.Childs && dto.Childs.length > 0) {
          this.Search(searchValue, dto.Childs, true);
        }
      }
    } else {
      this.ClearSearchInput();
    }
  }

  ClearSearchInput(event?: any) {
    this.FilteredUo = this.Model?.Dtos;
  }

  Create() {
    this.navigatorService.PageAction = PageAction.New;
    this.navigatorService.ShowDialog(
      OrganizationUnitComponent,
      null,
      '45%',
      'fit-content',
      '200px',
      async (response) => {
        if (response?.Performed) {
          const tab = this.GetTab(response.Accounts);
          if (
            this.authenticationService.DocAccount?.Dto?.Id <= 0 &&
            this.authenticationService.DocAccount?.Dto?.IsAdmin
          ) {
            await this.authenticationService.Initialize();
          }
          await this.Load(tab);
          const path = this.GetBranch(response.UO, this.FilteredUo);
          if (path) {
            for (const depth of path) {
              this.UoTree.Expand(depth, this.UoTree.GetIndex(depth));
            }
          }
        }
      }
    );
  }

  GetTab(accounts: Array<AccountDto>) {
    const user = accounts.find(
      (u) => u.Username === this.authenticationService.CurrentUser?.Username
    );
    if (user) {
      return 1;
    }
    return 2;
  }

  GetBranch(
    uo: OrganizationUnitDto,
    uos: Array<OrganizationUnitDto>,
    path: Array<OrganizationUnitDto> = []
  ) {
    for (const dto of uos) {
      if (dto.Id === uo.Id) {
        path.push(dto);
        return path;
      } else {
        if (dto.Childs?.length > 0) {
          path.push(dto);
          return this.GetBranch(uo, dto.Childs, path);
        } else {
          if (!dto.Parent) {
            path = [];
          }
        }
      }
    }
    return path;
  }

  // async Action($event) {
  //   if ($event?.Action === 'EXPORT' && $event?.UoDto) {
  //     const dtos = new Array<OrganizationUnitDto>();
  //     dtos.push($event?.UoDto);
  //     this.Export(dtos);
  //   } else if ($event?.Action === 'DELETE' && $event?.UoDto) {
  //     if ($event?.UoDto) {
  //       const data = new GenericDialogModel();
  //       data.Name = $event?.UoDto.Name;
  //       data.Icon = '../../../../../assets/icon/group-black.svg';
  //       data.Title = this.navigatorService.Dictionary?.Delete;
  //       data.Message = this.navigatorService.Dictionary?.AreYouSureWantMsg + ' ' +
  //         this.navigatorService.Dictionary?.MoveToTrash.toLowerCase() + '<br>' + $event?.UoDto.Name + '?';
  //       data.Description = this.navigatorService.Dictionary?.AssociatedUsers + ': ' + $event?.UoDto.CountAccount + ', ' +
  //         this.navigatorService.Dictionary?.AssociatedFiles + ': ' + $event?.UoDto.CountFiles;
  //       data.ConfirmBtnLabel = this.navigatorService.Dictionary?.Delete;
  //       data.CancelBtnLabel = this.navigatorService.Dictionary?.Cancel;
  //       this.navigatorService.ShowDialog(GenericConfirmDialogComponent, data, '45%', 'fit-content', '200px', async (performed) => {
  //         if (performed === GenericDialogAction.CONFIRM) {
  //           this.navigatorService.StartLoading();
  //           $event.UoDto.Trashed = true;
  //           let model = new OrganizationUnitModel();
  //           model.UpdateProperties = ['Trashed'];
  //           model.Dto = $event?.UoDto;
  //           model = await this.Controller.CreateOrUpdate(model);
  //           if (model?.Performed) {
  //             this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MoveToTrashSuccessMessage, data.Name);
  //             await this.Load();
  //           } else {
  //             this.navigatorService.ShowSnackBar(this.navigatorService.Dictionary?.MessageGenericError);
  //           }
  //         }
  //         this.navigatorService.StopLoading();
  //       });
  //     }
  //   }
  // }

  // // #region Export
  // async Export(uoDtos) {
  //   this.navigatorService.StartLoading();
  //   const roleHeader = ['Id', 'Name', 'Permission'];
  //   const uoHeader = ['Id', 'Name', 'Description', 'OfficeCode', 'Parent', 'FolderName'];
  //   const accountHeader = ['Id', 'Username', 'DisplayName', 'UoId'];
  //   const accountRoleHeader = ['Id', 'UoId', 'AccountId', 'RoleId', 'Start', 'End'];
  //   await this.BindUsersUo(uoDtos);
  //   let modelExport = [
  //     { Name: 'UO', Data: { Header: uoHeader, Rows: uoHeader.toString().replace(/,/g, ';') + '\r\n' } },
  //     { Name: 'Account', Data: { Header: accountHeader, Rows: accountHeader.toString().replace(/,/g, ';') + '\r\n' } },
  //     { Name: 'AccountRole', Data: { Header: accountRoleHeader, Rows: accountRoleHeader.toString().replace(/,/g, ';') + '\r\n' } }
  //   ];
  //   modelExport = this.ExportUos(uoDtos, modelExport);
  //   const roleRows = this.commonService.ConvertToCSV(this.RoleModel?.Dtos, roleHeader);
  //   const fileNameZip = 'Uo_export';
  //   const uoModel = modelExport.find(m => m.Name === 'UO');
  //   const accountModel = modelExport.find(m => m.Name === 'Account');
  //   const accountRoleModel = modelExport.find(m => m.Name === 'AccountRole');
  //   this.commonService.CreateZip([
  //     new CustomFileModel('File-1_OrganizationUnit.csv', uoModel.Data.Rows),
  //     new CustomFileModel('File-2_OrganizationUnitAccounts.csv', accountModel.Data.Rows),
  //     new CustomFileModel('File-3_AccountRole.csv', accountRoleModel.Data.Rows),
  //     new CustomFileModel('File-4_Role.csv', roleRows)],
  //     fileNameZip);
  //   this.navigatorService.StopLoading();
  // }

  // ExportUos(uoDtos: Array<OrganizationUnitDto>, model) {
  //   model = this.ExportUo(uoDtos, model);
  //   return model;
  // }

  // ExportUo(uoDtos: OrganizationUnitDto[], model: any) {
  //   const uoModel = model.find(m => m.Name === 'UO');
  //   const accountModel = model.find(m => m.Name === 'Account');
  //   const accountRoleModel = model.find(m => m.Name === 'AccountRole');
  //   for (const dto of uoDtos) {
  //     uoModel.Data.Rows += this.commonService.ConvertToCSV([dto], uoModel.Data.Header);
  //     accountModel.Data.Rows += this.commonService.ConvertToCSV(dto.Users, accountModel.Data.Header);
  //     const accountsRole = dto.Users.map(userRole => ({
  //       Id: userRole.AccountRoleId,
  //       UoId: userRole.UoId,
  //       AccountId: userRole.Id,
  //       RoleId: userRole.RoleId,
  //       Start: userRole.Start,
  //       End: userRole.End
  //     }));
  //     accountRoleModel.Data.Rows += this.commonService.ConvertToCSV(accountsRole, accountRoleModel.Data.Header);
  //     if (dto.Childs && dto.Childs.length > 0) {
  //       return this.ExportUo(dto.Childs, model);
  //     }
  //   }
  //   return model;
  // }

  // async BindUsersUo(dtos: OrganizationUnitDto[]) {
  //   for (const dto of dtos) {
  //     let accountModel = new AccountModel();
  //     accountModel.Filter = new AccountFilter();
  //     accountModel.Filter.UoId = dto.Id;
  //     accountModel = await this.AccountController.Get(accountModel);
  //     if (accountModel.Performed && accountModel.Dtos && accountModel.Dtos.length > 0) {
  //       if (!dto.Users) {
  //         dto.Users = new Array<AccountDto>();
  //       }
  //       dto.Users.push(...accountModel.Dtos);
  //     }
  //     if (dto.Childs && dto.Childs.length > 0) {
  //       await this.BindUsersUo(dto.Childs);
  //     }
  //   }
  // }
  // //#endregion Export

  // Sort(tableColumn: string) {
  //   if (tableColumn === 'officeCode') {
  //     if (!this.SortAscOfficeCode) {
  //       // Sort asc
  //       this.FilteredUo?.sort((a, b) => (a.OfficeCode.toLowerCase() > b.OfficeCode.toLowerCase()) ? 1 : -1);
  //     } else if (this.SortAscOfficeCode) {
  //       // Sort desc
  //       this.FilteredUo?.sort((a, b) => (a.OfficeCode.toLowerCase() < b.OfficeCode.toLowerCase()) ? 1 : -1);
  //     }
  //     this.SortAscOfficeCode = !this.SortAscOfficeCode;
  //   }
  //   if (tableColumn === 'name') {
  //     if (!this.SortAscName) {
  //       // Sort asc
  //       this.FilteredUo?.sort((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : -1);
  //     } else if (this.SortAscName) {
  //       // Sort desc
  //       this.FilteredUo?.sort((a, b) => (a.Name.toLowerCase() < b.Name.toLowerCase()) ? 1 : -1);
  //     }
  //     this.SortAscName = !this.SortAscName;
  //   }
  // }
}
