import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ObjectDto } from '../../../models/ObjectModel';
import { SettingsController } from '../../../../controllers/SettingsController';
import { FileDto, FileModel } from '../../../models/FileModel';
import { DisplayMode } from '../../../doc.configuration';
import { NavigatorService } from '../../../../navigator.services';
import { CommonService } from '../../../../common.service';
import { DataService } from '../../../../data.service';
import { AuthService } from '../../../../auth.service';
import {
  AnnotationObject,
  Annotations,
  ImageAnnotation,
  PositionAnnotation,
  SignatureAccountDto,
  SignatureAccountModel,
  SignatureParams,
} from '../../../../models/SignatureAccount';
// import { ResizedEvent } from 'angular-resize-event';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent implements OnInit {
  ObjectDto: ObjectDto;
  SigatureAccountModel: SignatureAccountModel;
  Model: SignatureAccountModel;
  SettingsController: SettingsController;
  Message: string;
  SignatureModes: Array<any>;
  SignatureTypes: Array<any>;
  Step = 1;
  Extension: string;
  FileModel: FileModel;
  Pages: Array<number>;
  Preview: Array<FileDto>;
  CurrentPage = 1;
  DisplayMode: DisplayMode;
  DragPosition = { x: 0, y: 50 };
  constructor(
    public navigatorService: NavigatorService,
    private dataService: DataService,
    public commonService: CommonService,
    public dialogRef: MatDialogRef<SignatureComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.Model = new SignatureAccountModel();
    this.Model.Dto = new SignatureAccountDto();
    this.Model.Dto.Params = new SignatureParams();
    this.ObjectDto = data;
    this.SigatureAccountModel = new SignatureAccountModel();
    this.SettingsController = new SettingsController(dataService);
    this.FileModel = new FileModel();
    this.FileModel.Skip = 0;
    this.FileModel.Take = 1;
    this.DisplayMode = DisplayMode.SINGLE;
    this.SignatureModes = new Array<any>();
    this.SignatureModes.push(
      { Id: 1, Display: 'CADES', Value: 'CADES', Selected: false },
      { Id: 2, Display: 'PADES', Value: 'PADES', Selected: false }
    );
    this.SignatureTypes = new Array<any>();
    this.SignatureTypes.push(
      {
        Id: 1,
        Display: navigatorService.Dictionary?.GraphicSign,
        Value: false,
        Selected: false,
      },
      {
        Id: 2,
        Display: navigatorService.Dictionary?.InvisibleSign,
        Value: true,
        Selected: false,
      }
    );
  }

  ngOnInit(): void {
    this.Load();
  }

  async Load() {
    this.navigatorService.StartLoading();
    this.SigatureAccountModel =
      await this.SettingsController.GetSignatureAccount(
        this.SigatureAccountModel
      );
    if (this.SigatureAccountModel.Performed) {
      this.Model.Dto =
        this.SigatureAccountModel.Dtos.length === 1
          ? this.SigatureAccountModel.Dtos[0]
          : null;
      this.BuildSignModel();
    }
    this.navigatorService.StopLoading();
  }

  async Sign() {
    if (this.ValidateForm()) {
      this.navigatorService.StartLoading();
      const fileModel = await this.commonService.ConvertDocument(
        this.ObjectDto
      );
      if (fileModel.Performed) {
        this.Model.Dto.Document = fileModel.Dto.File;
        this.Model.Dto.FileId = this.ObjectDto.Id;
        this.SetGraphicSignPosition();
        this.Model = await this.SettingsController.Sign(this.Model);
        if (this.Model.Performed) {
          this.navigatorService.ShowSnackBar(
            this.navigatorService.Dictionary?.ToSignSuccess,
            this.ObjectDto?.Name +
              this.commonService.GetExtension(this.ObjectDto.PhysicalName)
          );
          this.dialogRef.close(this.Model?.Entity);
        } else {
          if (this.Model.Code === 500) {
            this.navigatorService.ShowSnackBar(
              this.navigatorService.Dictionary?.MessageGenericError
            );
          } else if (this.Model.Code == 0o4) {
            this.Message = this.navigatorService.Dictionary?.InvalidOTP;
          } else if (this.Model.Code == 204) {
            this.Message =
              this.navigatorService.Dictionary?.MessageGenericError;
          } else if (this.Model?.Dto?.Params?.otpRequested?.tokenRequested) {
            this.Message = this.navigatorService.Dictionary?.TypeOtp;
            this.Step = 3;
          }
        }
      } else {
        this.navigatorService.ShowSnackBar(
          this.navigatorService.Dictionary?.MessageConvertFail,
          this.ObjectDto.Name
        );
      }
      this.navigatorService.StopLoading();
    }
  }

  ValidateForm() {
    if (this.Model.Dto?.Id > 0 && this.Model.Dto?.ServiceId > 0) {
      if (this.Step === 1) {
        if (
          !this.Model.Dto.Params.format ||
          this.Model.Dto.Params.invisibleSignature === null
        ) {
          return false;
        } else if (
          this.Model.Dto.Params.format === 'PADES' &&
          (!this.Model.Dto.Params.annotations.reason.value ||
            !this.Model.Dto.Params.annotations.location.value)
        ) {
          return false;
        }
      } else if (this.Step === 2) {
        if (
          this.Model.Dto.Params.format === 'PADES' &&
          this.Model.Dto.Params.invisibleSignature === false
        ) {
          const page = this.FileModel.Dtos.find((q) => q.Selected);
          if (!page) {
            return false;
          }
        }
      }
    } else {
      return false;
    }
    return true;
  }

  BuildSignModel() {
    if (this.Model.Dto) {
      this.Model.Dto.Params = new SignatureParams();
      this.Extension = this.commonService.GetExtension(
        this.ObjectDto.PhysicalName
      );
      if (
        this.Extension &&
        (this.Extension === 'docx' ||
          this.Extension === 'doc' ||
          this.Extension === 'odt' ||
          this.Extension === 'rtf' ||
          this.Extension === 'txt' ||
          this.Extension === 'pdf')
      ) {
        this.Model.Dto.MimeType = this.ObjectDto.MimeType; // 'application/pdf';
        this.Model.Dto.Params.fileName = this.ObjectDto.PhysicalName.replace(
          this.Extension,
          'pdf'
        );
        this.Model.Dto.Params.imageOnly = false;
        this.Model.Dto.Params.format = this.SignatureModes.find(
          (q) => q.Selected === true
        )?.Value;
        this.Model.Dto.Params.invisibleSignature = this.SignatureTypes.find(
          (q) => q.Selected === true
        )?.Value;

        this.Model.Dto.Params.annotations = new Annotations();

        this.Model.Dto.Params.annotations.contact = new AnnotationObject();
        this.Model.Dto.Params.annotations.contact.type = 'CONTACT';
        this.Model.Dto.Params.annotations.contact.value = this.Model.Dto.Email;

        this.Model.Dto.Params.annotations.reason = new AnnotationObject();
        this.Model.Dto.Params.annotations.reason.type = 'REASON';
        this.Model.Dto.Params.annotations.reason.value = null;

        this.Model.Dto.Params.annotations.location = new AnnotationObject();
        this.Model.Dto.Params.annotations.location.type = 'LOCATION';
        this.Model.Dto.Params.annotations.location.value = null;

        this.Model.Dto.Params.annotations.image = new ImageAnnotation();
        this.Model.Dto.Params.annotations.image.mimeType = 'image/jpeg';
        this.Model.Dto.Params.annotations.image.data = '___B64___';

        //graphyc
        this.SignatureTypes.map((q) => (q.Selected = false));
        if (this.Model.Dto.Params.format === 'CADES') {
          this.SignatureTypes.find((q) => q.Value === true).Selected = true;
          this.Model.Dto.Params.invisibleSignature = true;
        }
      } else {
        this.Model.Dto.MimeType = this.ObjectDto.MimeType;
        this.Model.Dto.Params.format = this.SignatureModes.find(
          (q) => q.Selected === true
        )?.Value;
        this.Model.Dto.Params.fileName = this.ObjectDto.PhysicalName.replace(
          this.Extension,
          'p7m'
        );

        //graphyc
        const modeId = this.SignatureModes.find((q) => q.Value === 'CADES')?.Id;
        this.SignatureModes.map((q) => (q.Selected = false));
        this.Model.Dto.Params.format = this.SignatureModes.find(
          (q) => q.Id === modeId
        )?.Value;
        this.SignatureModes.find((q) => q.Id === modeId).Selected = true;
        this.SignatureTypes.map((q) => (q.Selected = false));
        this.SignatureTypes.find((q) => q.Value === true).Selected = true;
        this.Model.Dto.Params.invisibleSignature = true;
      }
    }
  }

  //#region step 1
  SignatureAccountChange(event) {
    if (event) {
      this.Model.Dto = this.SigatureAccountModel.Dtos.find(
        (q) => q.Id === event.value
      );
      this.BuildSignModel();
    }
  }

  SignatureModeChange(event) {
    if (event) {
      this.SignatureModes.map((q) => (q.Selected = false));
      this.Model.Dto.Params.format = this.SignatureModes.find(
        (q) => q.Id === event.value
      )?.Value;
      this.SignatureModes.find((q) => q.Id === event.value).Selected = true;
      this.BuildSignModel();
    }
  }

  SignatureTypeChange(event) {
    if (event) {
      this.SignatureTypes.map((q) => (q.Selected = false));
      this.Model.Dto.Params.invisibleSignature = this.SignatureTypes.find(
        (q) => q.Id === event.value
      )?.Value;
      this.SignatureTypes.find((q) => q.Id === event.value).Selected = true;
      this.Model.Dto.Params.annotations.image.data = !this.Model.Dto.Params
        .invisibleSignature
        ? this.Model.Dto.RealSignature
        : '___B64___';
    }
  }

  GetType() {
    return this.SignatureTypes?.find((q) => q.Selected === true)?.Id;
  }

  GetMode() {
    return this.SignatureModes?.find((q) => q.Selected === true)?.Id;
  }
  //#endregion

  //region Step2
  async GetPreview() {
    if (!this.FileModel.Dto?.File) {
      this.FileModel = await this.commonService.ConvertDocument(
        this.ObjectDto,
        this.FileModel.Skip,
        this.FileModel.Take
      );
    }
    if (this.FileModel?.Performed) {
      if (
        !this.FileModel.Dto.Pages ||
        (this.FileModel.Dto.Pages && this.FileModel.Dto.Pages.length <= 0)
      ) {
        this.FileModel.Dto.Pages = [1];
      }
      const filter = this.FileModel.Dtos.filter((q) =>
        this.FileModel.Dto.Pages.includes(parseInt(q.PhysicalName, 10))
      );
      if (filter?.length !== this.FileModel.Dto.Pages.length) {
        let model = new FileModel();
        model.Skip = this.FileModel.Skip;
        model.Take = this.FileModel.Take;
        model.Dto = this.FileModel.Dto;
        model = await this.SettingsController.GetPreview(model);
        this.FileModel.Count = model.Count;
        if (model?.Performed) {
          this.SetCssClass(model.Dtos);
          for (const dto of model.Dtos) {
            if (
              !this.FileModel.Dtos.find(
                (q) => q.PhysicalName == dto.PhysicalName
              )
            ) {
              this.FileModel.Dtos.push(dto);
            }
          }
        }
      }
    } else {
      this.navigatorService.ShowSnackBar(
        this.navigatorService.Dictionary?.MessageGenericError
      );
    }
  }

  GetPages(): number[] {
    if (this.DisplayMode === DisplayMode.MULTIPLE) {
      if (this.CurrentPage % 2 === 0) {
        return [this.CurrentPage - 1, this.CurrentPage];
      } else {
        return [this.CurrentPage, this.CurrentPage + 1];
      }
    }
    return [this.CurrentPage];
  }

  SetCssClass(dtos: FileDto[]) {
    for (const dto of dtos) {
      let cssClass =
        this.DisplayMode === DisplayMode.SINGLE
          ? 'image-vertical'
          : 'multiple-vertical';
      if (dto.Dimension['width'] > dto.Dimension['height']) {
        cssClass = 'image-horizzontal';
      }
      dto.CssClass = cssClass;
    }
  }

  SelectedImage(dto: FileDto) {
    this.Preview.map((q) => (q.Selected = false));
    this.FileModel.Dtos.map((q) => (q.Selected = false));

    dto.Selected = true;
    // this.FileModel.Dtos.find(q => q.PhysicalName === dto.PhysicalName).Selected = true;
  }

  async Paging(page: number = 1) {
    this.FileModel?.Dtos?.map((o) => (o.Selected = false));
    this.FileModel.Dtos.map((q) => (q.ShowSign = false));
    this.Preview = new Array<FileDto>();
    const totalPages = Math.ceil(this.FileModel.Count / this.FileModel.Take);
    this.Pages = new Array<number>();
    for (let i = 0; i < totalPages; i++) {
      this.Pages.push(i + 1);
    }
    if (page > this.Pages.length) {
      page = this.Pages.length;
    }
    this.CurrentPage = page;

    if (this.DisplayMode === DisplayMode.SINGLE) {
      this.FileModel.Skip = this.FileModel.Take * (page - 1);
      this.FileModel.Dto.Pages = [this.CurrentPage];
      await this.GetPreview();
      this.Preview = this.FileModel.Dtos.filter(
        (q) => q.PhysicalName == this.CurrentPage.toString()
      );
    } else {
      const count = page * 2;
      const pages = [count - 1, count];
      if (count > this.FileModel.Count) {
        pages.pop();
      }
      this.FileModel.Dto.Pages = pages;
      await this.GetPreview();
      this.Preview = this.FileModel.Dtos.filter((q) =>
        pages.includes(parseInt(q.PhysicalName, 10))
      );
    }
  }

  async SliderPagingClick(action: string) {
    this.navigatorService.StartLoading();
    if (action === 'next') {
      const page =
        this.CurrentPage + 1 <= this.Pages.length
          ? this.CurrentPage + 1
          : this.Pages.length;
      await this.Paging(page);
    } else if (action === 'previous') {
      const page = this.CurrentPage - 1 > 0 ? this.CurrentPage - 1 : 1;
      await this.Paging(page);
    }
    this.navigatorService.StopLoading();
  }

  async CurrentPageChange(value) {
    if (value && !isNaN(parseInt(value.toString(), 10))) {
      value = parseInt(value.toString(), 10);
      if (value <= this.Pages.length && value >= 1) {
        this.navigatorService.StartLoading();
        const page =
          value >= 1 && value <= this.Pages.length ? value : this.Pages.length;
        await this.Paging(parseInt(page, 10));
        this.navigatorService.StopLoading();
      }
    }
  }

  async ChangeDisplayMode(displaymode: string) {
    this.DisplayMode = DisplayMode[displaymode];
    this.FileModel.Take = this.DisplayMode === DisplayMode.SINGLE ? 1 : 2;
    this.navigatorService.StartLoading();
    this.FileModel.Dto.Pages = this.GetPages();
    this.SetCssClass(this.FileModel.Dtos);
    await this.Paging(this.CurrentPage);
    this.navigatorService.StopLoading();
  }

  AddGraphicSignature() {
    let page = null;
    if (this.DisplayMode === DisplayMode.SINGLE) {
      page = this.Preview.find(
        (q) => q.PhysicalName == this.CurrentPage.toString()
      );
      page.Selected = true;
    } else {
      page = this.Preview.find((q) => q.Selected);
    }
    if (page) {
      const id = 'img_page_' + page.PhysicalName;
      const control = document.getElementById(id);
      if (control) {
        this.DragPosition = { x: 100, y: -(control.clientHeight - 50) };
        this.BindPosition(this.DragPosition.x, this.DragPosition.y);
      }
      page.ShowSign = true;
    }
  }

  DisableAddSigButton() {
    const page = this.Preview.find((q) => q.Selected);
    if (!page && this.DisplayMode === DisplayMode.MULTIPLE) {
      return true;
    }
    return false;
  }

  SignatureDragEnded($event) {
    if ($event) {
      this.BindPosition($event.dropPoint.x, $event.dropPoint.y);

      // console.log('Drop Point');
      // console.log('x: ' + $event.dropPoint.x);
      // console.log('y: ' + $event.dropPoint.y);
      // console.log('Distance');
      // console.log('x: ' + $event.distance.x);
      // console.log('y: ' + $event.distance.y);
    }
  }

  BindPosition(x, y) {
    this.Model.Dto.Params.annotations.position =
      new Array<PositionAnnotation>();
    const page = this.Preview.find((q) => q.Selected);
    const position = new PositionAnnotation();
    position.posX = x;
    position.posY = y;
    position.page = page.Id;
    this.Model.Dto.Params.annotations.position.push(position);
  }

  SetGraphicSignPosition() {
    const typeId = this.GetType();
    if (typeId && this.Step === 2) {
      const type = this.SignatureTypes.find((q) => q.Id === typeId);
      if (type && !type.Value) {
        const page = this.Preview.find((q) => q.Selected);
        const id = 'sign_' + page.PhysicalName;
        const img = document.getElementById(id);
        const previewBox = document
          .getElementById('img_page_' + page.PhysicalName)
          .getBoundingClientRect();
        const boxSignatureImage = document
          .getElementById('box-signature_' + page.PhysicalName)
          .getBoundingClientRect();
        const dtoPage = this.FileModel.Dtos.find((q) => q.Selected);
        let x = this.ConvertInRealWidthProportion(
          boxSignatureImage.left - previewBox.left,
          previewBox,
          dtoPage
        );
        this.Model.Dto.Params.annotations.position[0].posX = Math.round(x);
        let y = this.ConvertInRealHeightProportion(
          boxSignatureImage.top - previewBox.top,
          previewBox,
          dtoPage
        );
        const realHeight = this.ConvertInRealHeightProportion(
          y,
          previewBox,
          dtoPage
        );
        y = Math.abs(dtoPage.Dimension.height - realHeight);
        y -= img.clientHeight;
        this.Model.Dto.Params.annotations.position[0].posY = Math.round(y);
        this.Model.Dto.Params.annotations.position[0].page = parseInt(
          page.PhysicalName,
          10
        );
        if (img) {
          this.Model.Dto.Params.annotations.position[0].width = img.clientWidth;
          this.Model.Dto.Params.annotations.position[0].height =
            img.clientHeight;
        }
      }
    }
  }

  ConvertInRealWidthProportion(value: number, previewElement, dtoPage) {
    return (value * dtoPage.Dimension.width) / previewElement.width;
  }

  ConvertInRealHeightProportion(value: number, previewElement, dtoPage) {
    return (value * dtoPage.Dimension.height) / previewElement.height;
  }

  SignatureResized(event): void {}
  //#endregion step2

  ShowButton(type: string) {
    if (type === 'SIGN') {
      if (
        this.Model?.Dto?.Params?.invisibleSignature === true ||
        this.Step === 2 ||
        this.Step === 3 ||
        !this.Model?.Dto?.Params?.format
      ) {
        return true;
      }
    } else if (type === 'NEXT') {
      if (
        this.Model?.Dto?.Params?.format === 'PADES' &&
        this.Model?.Dto?.Params?.invisibleSignature === false &&
        this.Step < 2
      ) {
        return true;
      }
    }
    return false;
  }

  Back() {
    this.CurrentPage = 1;
    if (this.Step > 1) {
      if (this.Model?.Dto?.Params?.invisibleSignature === false) {
        this.Step -= 1;
      } else {
        this.Step -= 2;
      }
    }
  }

  async Next() {
    this.Step += 1;
    if (this.Step === 2) {
      this.navigatorService.StartLoading();
      await this.GetPreview();
      this.Paging(this.CurrentPage);
      this.navigatorService.StopLoading();
    }
  }
}
